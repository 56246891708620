import React, { useState } from 'react';
import burningSeedImage from '../../assets/images/subclasses/Burning/circleoftheburningseed.png';
import './ClassesPage.css';
import { useNavigate, useLocation } from 'react-router-dom';

const ClassesBurningSeed = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={burningSeedImage} 
          alt="Circle of the Burning Seed" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-details">
          <h1>Circle of the Burning Seed</h1>
        </div>
      </div>

      <div className="content-block">
        <h2>Overview</h2>
        <p>
          Druids of the Circle of the Burning Seed are conduits of fiery chaos and untamed growth. They embrace the destructive and creative forces of fire, wielding its primal energy to burn away obstacles and fuel life's resurgence in unpredictable ways. These druids are passionate, often impulsive, but their hearts burn with an enduring desire to see life flourish, even if it means embracing the chaos of the flame. To them, fire is not just destruction—it is transformation, renewal, and the raw potential of nature itself.
        </p>
        <p>
          These druids often dwell in areas where fire plays a crucial role in the ecosystem, such as forests prone to wildfires or volcanic regions. They understand that fire, while destructive, is a necessary part of nature's cycle, clearing away the old to make way for the new. Their rituals and practices often involve controlled burns and the use of fire to promote growth and change.
        </p>
      </div>

      <div className="content-block">
        <h2>Circle Spells</h2>
        <div className="level-indicator">Level 2nd</div>
        <p>Your connection to fiery chaos grants you access to certain spells. You always have the following spells prepared, and they don't count against the number of spells you can prepare each day.</p>
        <div className="trait">
          <div className="trait-header">
            <h3>3rd Level</h3>
          </div>
          <p>Flame Blade, Flaming Sphere</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>5th Level</h3>
          </div>
          <p>Fireball, Plant Growth</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>7th Level</h3>
          </div>
          <p>Wall of Fire, Blight</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>9th Level</h3>
          </div>
          <p>Immolation, Tree Stride</p>
        </div>
      </div>

      <div className="content-block">
        <h2>Fiery Growth</h2>
        <div className="level-indicator">Level 2nd</div>
        <p>
          While in your Wild Shape, you can channel the fiery chaos within you to fuel rapid growth in the land around you. As a bonus action, you can expend a spell slot to unleash a burst of fiery energy. Each creature of your choice within 10 feet of you must make a Dexterity saving throw against your spell save DC. On a failed save, a creature takes 1d4 fire damage per round for each level of the spell slot expended and is entangled by rapidly growing plants for 1 round per level of the spell slot expended. On a successful save, a creature takes half as much damage and is not entangled.
        </p>
        <p>
          The plants created by this ability are considered magical and burn away harmlessly after the effect ends.
        </p>
      </div>

      <div className="content-block">
        <h2>Burning Fury</h2>
        <div className="level-indicator">Level 6th</div>
        <p>
          Your fiery spirit enhances the destructive force of your Wild Shape. While in Wild Shape, your melee weapon attacks deal an additional 1d4 fire damage and count as magical for the purpose of overcoming resistance to fire and nonmagical attacks and damage. The fire damage increases to 1d6 when you reach 10th level and 1d8 when you reach 14th level in this class.
        </p>
      </div>

      <div className="content-block">
        <h2>Elemental Transformation</h2>
        <div className="level-indicator">Level 10th</div>
        <p>
          When you use your Wild Shape feature, you can transform into a fire elemental version of the beast form you choose. In this form, your body becomes a blazing inferno, with flames licking off your skin and eyes burning like embers. You gain the following benefits:
        </p>
        <ul>
          <li><strong>Fire Resistance:</strong> Being made of fire you have immunity to fire damage.</li>
          <li><strong>Cold Vulnerability:</strong> You have vulnerability to cold damage. The icy chill of cold attacks cuts through your fiery form, dealing double damage.</li>
          <li><strong>Ignite:</strong> Your unarmed strikes deal an additional 1d6 fire damage.</li>
          <li><strong>Burning Aura:</strong> At the start of each of your turns, each creature within 10 feet of you takes 1d4 fire damage. The air around you shimmers with heat, and the ground beneath your feet smolders.</li>
          <li><strong>Elemental Movement:</strong> You gain a flying speed equal to your walking speed. Flames propel you through the air, leaving a trail of sparks and embers in your wake.</li>
        </ul>
        <p>
          You can use this feature a number of times equal to your proficiency bonus. You regain all expended uses when you finish a long rest.
        </p>
      </div>

      <div className="content-block">
        <h2>Fiery Embrace</h2>
        <div className="level-indicator">Level 14th</div>
        <p>
          The blazing energy of fiery plants burns within you, allowing you to unleash their wrath in battle. As an action while in Wild Shape, you can release a wave of searing plants in a 30-foot cone. Each creature in the cone must make a Dexterity saving throw against your spell save DC. On a failed save, a creature takes 6d6 fire damage and is entangled by the fiery plants. On a successful save, a creature takes half damage and is not entangled.
        </p>
        <p>
          The fiery plants created by this ability are considered magical. At the start of each entangled creature's turn, it takes 2d6 fire damage and must make a Strength (Athletics) check against your spell save DC to free itself from the entanglement. On a success, the creature escapes the entanglement; otherwise, it remains entangled.
        </p>
        <p>
          You can use this feature a number of times equal to your proficiency bonus (minimum of once). You regain all expended uses when you finish a long rest.
        </p>
      </div>

      <div className="content-block">
        <h2>Roleplaying a Circle of the Burning Seed Druid</h2>
        <p>
          As a druid of the Circle of the Burning Seed, you are deeply connected to the primal forces of fire and growth. Your impulsive nature and fiery spirit make you a passionate defender of nature, willing to take risks and embrace chaos to achieve your goals. You might be drawn to areas where fire plays a crucial role in the ecosystem, such as forests prone to wildfires or volcanic regions. Your rituals and practices often involve controlled burns and the use of fire to promote growth and change.
        </p>

        <h3>Personality Traits</h3>
        <ul>
          <li><strong>Impulsive:</strong> You act on your instincts and emotions, often taking risks and embracing chaos to achieve your goals.</li>
          <li><strong>Passionate:</strong> Your love for nature and desire to see life flourish burns brightly, driving you to protect and nurture the world around you.</li>
          <li><strong>Unpredictable:</strong> Your actions and decisions can be erratic, keeping your allies and enemies alike on their toes.</li>
          <li><strong>Resilient:</strong> You have a deep well of inner strength, allowing you to persevere through challenges and setbacks.</li>
        </ul>

        <h3>Ideals</h3>
        <ul>
          <li><strong>Transformation:</strong> Fire is a force of change and renewal, and you embrace its power to burn away the old and make way for the new.</li>
          <li><strong>Balance:</strong> You understand that destruction and creation are two sides of the same coin, and you strive to maintain the natural balance of the world.</li>
          <li><strong>Growth:</strong> You believe in the potential for growth and rebirth, even in the most desolate and barren places.</li>
          <li><strong>Chaos:</strong> You embrace the unpredictable nature of fire and use it to fuel your actions and decisions, trusting that the chaos will lead to new opportunities and possibilities.</li>
        </ul>

        <h3>Bonds</h3>
        <ul>
          <li><strong>Nature:</strong> You have a deep connection to a specific natural area, such as a forest, mountain, or river, and you are committed to protecting and nurturing it.</li>
          <li><strong>Community:</strong> You are part of a close-knit group of druids or nature-worshippers, and you value the bonds and friendships you have formed with them.</li>
          <li><strong>Mentor:</strong> You have a wise and experienced mentor who has guided you on your path as a druid, and you strive to live up to their teachings and expectations.</li>
          <li><strong>Enemy:</strong> You have a sworn enemy who threatens the natural world, and you are determined to stop them at any cost.</li>
        </ul>

        <h3>Flaws</h3>
        <ul>
          <li><strong>Reckless:</strong> Your impulsive nature can lead you to take unnecessary risks, putting yourself and others in danger.</li>
          <li><strong>Stubborn:</strong> You can be inflexible and unwilling to change your mind, even in the face of new information or changing circumstances.</li>
          <li><strong>Temperamental:</strong> Your fiery spirit can make you prone to outbursts of anger or frustration, which can strain your relationships with others.</li>
          <li><strong>Obsessive:</strong> You can become fixated on a particular goal or idea, to the exclusion of all else, which can blind you to other important considerations.</li>
        </ul>
      </div>

      <div className="content-block">
        <h2>Equipment</h2>
        <p>As a druid of the Circle of the Burning Seed, you carry a variety of tools and items that help you channel your fiery powers and connect with nature. Your equipment might include:</p>
        <ul>
          <li>A druidic focus, such as a staff or totem, carved from wood and adorned with symbols of fire and growth.</li>
          <li>A set of traveler's clothes, suitable for moving through wild and rugged terrain.</li>
          <li>A backpack containing a bedroll, a mess kit, a set of 10 torches, a tinderbox, and a waterskin.</li>
          <li>A belt pouch containing 10 gold pieces.</li>
          <li>A small pouch of seeds and plant cuttings, which you use to promote growth and regeneration in the areas you visit.</li>
          <li>A vial of holy water, which you use to purify and bless the natural world.</li>
          <li>A set of explorer's tools, which you use to navigate and survive in the wilderness.</li>
        </ul>
      </div>

      <div className="content-block">
        <h2>Customization Options</h2>
        <p>As you advance in your journey as a druid of the Circle of the Burning Seed, you may choose to customize your abilities and equipment to better suit your playstyle and the challenges you face. Some customization options might include:</p>
        
        <h3>Spell Selection</h3>
        <p>As you gain access to higher-level spells, you can choose to focus on spells that deal fire damage, promote growth and regeneration, or control and manipulate the battlefield.</p>
        
        <h3>Wild Shape Forms</h3>
        <p>You can choose to specialize in certain Wild Shape forms that complement your fiery powers, such as a fire elemental or a phoenix.</p>
        
        <h3>Equipment Upgrades</h3>
        <p>You can invest in upgraded equipment, such as a staff of fire or a cloak of resistance to fire damage, to enhance your abilities and protect yourself from harm.</p>
        
        <h3>Feats</h3>
        <p>You can choose feats that complement your playstyle, such as Elemental Adept (Fire) to ignore resistance to fire damage, or War Caster to improve your spellcasting in combat.</p>
      </div>

      <div className="content-block">
        <h2>Multiclassing</h2>
        <p>If you choose to multiclass, you can combine your druidic powers with the abilities of another class to create a unique and versatile character. Some multiclassing options might include:</p>
        
        <h3>Barbarian</h3>
        <p>The rage of the barbarian can complement your fiery spirit, allowing you to deal even more damage in combat and enhancing your survivability.</p>
        
        <h3>Sorcerer (Wild Magic)</h3>
        <p>The unpredictable nature of wild magic can complement your chaotic powers, allowing you to cast spells with unexpected and powerful effects.</p>
        
        <h3>Ranger</h3>
        <p>The ranger's tracking and survival skills can complement your connection to nature, allowing you to move stealthily through the wilderness and take down enemies from a distance.</p>
        
        <h3>Cleric (Light Domain)</h3>
        <p>The cleric's healing and support spells can complement your radiant damage, allowing you to protect and heal your allies while dealing damage to your enemies.</p>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={burningSeedImage} 
                alt="Circle of the Burning Seed"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={burningSeedImage}
                download="BurningSeed.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesBurningSeed; 