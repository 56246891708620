import React, { useState } from 'react';
import lifedrinkerImage from '../../assets/images/subclasses/Lifedrinker/wayofthelifedrinker.jpg';
import './ClassesPage.css';
import { useNavigate, useLocation } from 'react-router-dom';

const ClassesWayOfTheLifedrinker = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={lifedrinkerImage} 
          alt="Way of the Lifedrinker" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-details">
          <h1>Way of the Lifedrinker</h1>
        </div>
      </div>

      <div className="content-block">
        <h2>Overview</h2>
        <p>
          The Way of the Lifedrinker is a monastic tradition centered on the mastery of Ki as both a weapon and a resource. Lifedrinkers do not merely cultivate their own life energy—they connect to the ebb and flow of vitality that pulses through all living things. By learning to draw on this interconnected energy, they sustain themselves in the most dire circumstances, turn their enemies' strength against them, and weave life and death into a seamless cycle of power.
        </p>
        <p>
          This path is not for the faint-hearted. Many view its practices as macabre or unnatural, yet the Lifedrinker understands the truth: their discipline honors the natural cycle of life and death. Every act of draining energy is balanced by the preservation of life elsewhere, reflecting a profound respect for the delicate equilibrium of existence.
        </p>
      </div>

      <div className="content-block">
        <h2>Features</h2>
        <div className="trait">
          <div className="trait-header">
            <h3>Essence Drain</h3>
            <span className="level-tag">Level 3rd</span>
          </div>
          <p>Through mastery of their life force, a Lifedrinker can sap the vital essence of their foes, converting it into nourishment for themselves.</p>
          <p>
            You have learned to drain the Ki from other beings to revitalize yourself. As a bonus action, you can expend 1 Ki point to make a special melee strike against a creature. On a hit, you deal damage equal to your martial arts die and regain hit points equal to the damage dealt. Additionally, choose one of the following effects:
          </p>
          <p>Ki Disrupt: The target has disadvantage on attack rolls until the start of your next turn.</p>
          <p>Weakened Spirit: The target's movement speed is reduced by 10 feet until the start of your next turn.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Disrupting Strike</h3>
            <span className="level-tag">Level 3rd</span>
          </div>
          <p>
            Through rigorous training and unwavering focus, Disrupting Strikers hone their attacks to not only inflict physical damage but also disrupt the inner harmony of their foes. A strike from a Disrupting Striker can shatter a foe's concentration, unbalance their stance, or even temporarily disrupt their connection to the Weave.
          </p>
          <p>
            As a bonus action, you can expend 1 Ki point to unleash a blast of psychic force from your palm. Choose a target within 10 feet of you. The target must succeed on a Strength saving throw (DC = 8 + your proficiency bonus + your Wisdom modifier) or take psychic damage equal to your martial arts die and be knocked prone. The range of this ability increases as you gain levels in this class:
          </p>
          <p>3rd-4th Level: 10 ft</p>
          <p>5th-7th Level: 15 ft</p>
          <p>8th-11th Level: 20 ft</p>
          <p>12th-14th Level: 30 ft</p>
          <p>15th-17th Level: 40 ft</p>
          <p>18th-19th Level: 50 ft</p>
          <p>20th Level: 60 ft</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Ethereal Ward</h3>
            <span className="level-tag">Level 6th</span>
          </div>
          <p>
            As a master of Ki, you have learned to envelop yourself in a protective aura of spiritual energy. This ward enhances your resilience, making you harder to hit and more resistant to elemental and psychic damage. But more than that, it allows you to channel the life force of your attackers, turning their aggression against them.
          </p>
          <p>
            You can expend 2 Ki points as a bonus action to envelop yourself in a protective aura of Ki for 1 minute. While the ward is active, you gain the following benefits:
          </p>
          <p>- Resistance to one of the following damage types of your choice: bludgeoning, piercing, slashing, fire, cold, acid, lightning, thunder, poison, or psychic.</p>
          <p>- A +2 bonus to AC.</p>
          <p>Lifeforce Shield: When you activate Ethereal Ward, you gain temporary hit points equal to your Wisdom modifier plus half your monk level.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Ki Feedback</h3>
            <span className="level-tag">Level 11th</span>
          </div>
          <p>
            When you are hit by a melee attack, you can use your reaction to expend 1 Ki point to deal psychic damage to the attacker equal to your martial arts die plus your Wisdom modifier. The attacker must also make a Constitution saving throw (DC = 8 + your proficiency bonus + your Wisdom modifier). On a failure, the attacker has disadvantage on its next attack roll. This represents your ability to disrupt an opponent's Ki at the moment of contact, making their next strike less effective.
          </p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Lifeforce Mimicry</h3>
            <span className="level-tag">Level 17th</span>
          </div>
          <p>
            When a creature within 5 feet of you uses a spell, special ability, or attack, you can use your reaction to make a special melee attack against that creature. On a successful hit, you deal necrotic damage equal to your martial arts die and drain part of the creature's life force or Ki. For 1 minute, you may replicate the ability you interrupted, but at half its original power or effectiveness. You must have the appropriate action type available (e.g., action, bonus action, reaction) to use the copied effect. This ability can be used a number of times equal to your Wisdom modifier per long rest.
          </p>
        </div>
      </div>

      <div className="content-block">
        <h2>Role Playing</h2>
        <p>
          Monks who follow the Way of the Lifedrinker walk a path of balance and power, understanding the interconnectedness of all beings through the flow of Ki. While their methods might be perceived as unsettling, they are driven by a deep respect for life and the delicate equilibrium between creation and destruction.
        </p>

        <div className="trait">
          <div className="trait-header">
            <h3>Personality Traits</h3>
          </div>
          <p><strong>Disciplined:</strong> You maintain rigorous control over your mind and body, understanding that Ki manipulation requires focus and precision.</p>
          <p><strong>Perceptive:</strong> You are keenly aware of the subtle energies that flow through all living beings, sensing their emotions and intentions.</p>
          <p><strong>Pragmatic:</strong> You view life and death as parts of a natural cycle, understanding that energy is constantly exchanged between beings.</p>
          <p><strong>Reserved:</strong> You carry yourself with a quiet intensity, your mastery of Ki evident in your controlled movements and focused gaze.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Ideals</h3>
          </div>
          <p><strong>Balance:</strong> You strive to maintain equilibrium in all things, understanding that disruption of the natural flow of Ki can have devastating consequences.</p>
          <p><strong>Harmony:</strong> You seek to live in harmony with the world around you, respecting the interconnectedness of all beings.</p>
          <p><strong>Self-Improvement:</strong> You constantly seek to refine your mastery of Ki, pushing the limits of your abilities and understanding.</p>
          <p><strong>Knowledge:</strong> You delve into ancient texts and forgotten teachings, seeking to unravel the mysteries of Ki and its connection to life and death.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Bonds</h3>
          </div>
          <p><strong>The Protiengier's Legacy:</strong> You honor the memory of the first Lifedrinker, seeking to emulate their mastery of Ki.</p>
          <p><strong>Lifedrinker Community:</strong> You share a unique bond with other Lifedrinkers, exchanging knowledge and experiences.</p>
          <p><strong>The Cycle of Life:</strong> You are fascinated by the flow of energy between beings, observing the natural cycles with reverence.</p>
          <p><strong>The Living World:</strong> You feel a deep connection to the natural world, drawing strength and inspiration from its vibrant energy.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Flaws</h3>
          </div>
          <p><strong>Isolation:</strong> Your unique abilities and unsettling methods can lead to isolation from others.</p>
          <p><strong>Temptation:</strong> The power to drain life force can be intoxicating, and you must constantly resist the urge to abuse your abilities.</p>
          <p><strong>Secretiveness:</strong> You guard the secrets of your Ki manipulation techniques closely.</p>
          <p><strong>Overconfidence:</strong> Your mastery of Ki can lead to arrogance, making you underestimate opponents.</p>
        </div>
      </div>

      <div className="content-block">
        <h2>Equipment</h2>
        <p>As a Way of the Lifedrinker monk, your equipment reflects your disciplined lifestyle and focus on Ki manipulation:</p>
        <ul>
          <li>Simple robes or comfortable clothing that allows for freedom of movement</li>
          <li>A small pouch containing herbs and medicinal supplies for maintaining physical well-being</li>
          <li>A scroll or book containing ancient teachings on Ki manipulation and meditation techniques</li>
          <li>A set of prayer beads or a meditation cushion for focusing your mind and enhancing Ki connection</li>
        </ul>
      </div>

      <div className="content-block">
        <h2>Multiclassing</h2>
        <p>To enhance your capabilities as a Way of the Lifedrinker monk, consider multiclassing with:</p>
        <div className="trait">
          <p><strong>Rogue (Assassin):</strong> The rogue's stealth and agility complement your monk abilities, allowing you to strike swiftly and silently.</p>
          <p><strong>Warlock (Hexblade):</strong> The warlock's pact magic and eldritch invocations can enhance your combat prowess and ability to drain life force.</p>
          <p><strong>Fighter (Battle Master):</strong> The fighter's martial expertise and combat maneuvers can further enhance your battlefield control.</p>
          <p><strong>Ranger (Hunter):</strong> The ranger's tracking and survival skills complement your monk's agility and perception.</p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={lifedrinkerImage} 
                alt="Way of the Lifedrinker"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={lifedrinkerImage}
                download="WayOfTheLifedrinker.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesWayOfTheLifedrinker; 