import React, { useState } from 'react';
import dronewrightImage from '../../assets/images/subclasses/Dronewright/dronewright.jpg';
import './ClassesPage.css'; // Make sure this CSS file defines styles for .content-block, .level-indicator, .trait, .trait-header
import { Link, useNavigate, useLocation } from 'react-router-dom';

const ClassesDronewright = () => {
  const [showFullImage, setShowFullImage] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img
          src={dronewrightImage}
          alt="Dronewright"
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-details">
          <h1>Dronewright</h1>
        </div>
      </div>

      {/* --- Overview Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Overview</h2>
        <p>
          Artificers who follow the path of the Dronewright pioneer the fusion of advanced technology with arcane traditions, their origins as diverse as the drones they construct. Some Dronewrights hail from steampunk worlds where clockwork mechanisms are imbued with magic, while others come from futuristic realities where technology and magic coexist seamlessly in space faring civilizations.
        </p>
        <p>
          Their journey often begins with the discovery of an enigmatic drone or the invention of one from scratch, sparking a lifelong pursuit of innovation. Regardless of origin, all Dronewrights share a deep understanding of how to weave the mechanical and magical into one cohesive force. Their creations act as extensions of their ingenuity, enhancing their physical prowess, serving as formidable weapons, or providing unparalleled utility.
        </p>
        <p>
          Despite the diversity in style and function—from rustic, gear-driven automatons to sleek, nanite-infused constructs—Dronewright drones share a unified design ethos: adaptability and synergy with their creator. This subclass celebrates the limitless potential of merging imagination with invention, granting Artificers the tools to reshape the battlefield, solve intricate problems, and leave their indelible mark on the world.
        </p>
      </div>

      {/* --- Drone Specialization Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Drone Specialization</h2>
        <div className="level-indicator">Level 3rd</div>
        <p>
          At 3rd level, you choose a specialization and create a drone, marking the beginning of your mastery in technological innovation. This specialization defines the role and capabilities of your drone, tailoring it to your specific needs and playstyle. Each path offers distinct features, ensuring that your drone becomes a unique and indispensable tool in your adventures. The specialization options are as follows:
        </p>
        <ul>
          <li><strong>Path of the Exoskeleton (Body Enhancement Drone):</strong> A path that integrates your drone with your physical form, enhancing your strength, mobility, and durability while providing tactical advantages.</li>
          <li><strong>Path of the Weaponized Drone:</strong> Focuses on offense, crafting a drone that serves as a powerful ranged or melee weapon, capable of devastating attacks and precision strikes.</li>
          <li><strong>Path of the Toolkit Drone:</strong> Specializes in utility and support, equipping your drone with versatile tools to heal, assist, and protect allies or solve complex problems.</li>
        </ul>
      </div>

      {/* --- Path of the Exoskeleton Description (Matches Structure) --- */}
      <div className="content-block">
        <h2>Path of the Exoskeleton</h2>
        <p>
          Path of the Exoskeleton (Body Enhancement Drone): This specialization represents the seamless integration of your drone with your physical form, transforming it into a cutting-edge exoskeleton. Designed for both combat and utility, the exoskeleton enhances your strength, mobility, and durability, making you a formidable force on the battlefield. Equipped with servo-assisted limbs, kinetic energy conduits, and advanced plating, this path ensures tactical superiority and adaptability in various scenarios. Whether climbing sheer surfaces, resisting extreme conditions, or engaging in melee combat, the exoskeleton embodies the perfect harmony of arcane and technological innovation.
        </p>
      </div>

      {/* --- Exoskeleton Activation Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Exoskeleton Activation</h2>
        <div className="level-indicator">Level 3rd</div>
        <p>
          As an action, you activate your body enhancement drone, which assembles around you to form an exoskeleton. The exoskeleton remains active for 1 hour per level, after which it requires a long rest to recharge. You may also dismiss the exoskeleton as a reaction. While active, you gain the following effects:
        </p>
        <div className="trait">
          <div className="trait-header">
            <h3>Enhanced Armor</h3>
          </div>
          <p>Your Armor Class (AC) becomes 13 + your Intelligence modifier + your proficiency bonus (you can still benefit from a shield). This shimmering, mechanical plating covers your body, harmonizing arcane energy with durable alloys to enhance your defense. It is designed to deflect attacks with precision, embodying the pinnacle of arcane engineering. This benefit lasts for the duration of the exoskeleton's activation.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Augmented Strength</h3>
          </div>
          <p>Your exoskeleton's servo-assisted limbs radiate an aura of mechanical might. You gain advantage on Strength checks and Strength saving throws for the duration. This enhancement is visually represented by hydraulics and glowing energy conduits surging with power, making feats of strength appear effortless and commanding awe on the battlefield.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Powered Tactical Repositioning</h3>
          </div>
          <p>Your exoskeleton enhances your mobility through advanced kinetic systems. Your walking speed increases by 10 feet, with visible energy conduits glowing faintly as they power your steps. This enhancement allows you to move with precision and agility, making it easier to navigate the battlefield and maintain strategic positioning.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Integrated Weaponry</h3>
          </div>
          <p>While the exoskeleton is active, your melee capabilities are enhanced with precision-guided technology. You can use your Intelligence modifier instead of Strength or Dexterity for attack and damage rolls with unarmed strikes and melee weapons. Your strikes emit a faint hum of arcane energy, showcasing the seamless integration of magic and machine, and leaving a shimmering trail of light with each swing or thrust.</p>
        </div>
      </div>

      {/* --- Exoskeleton Upgrades Level 5 Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Exoskeleton Upgrades</h2>
        <div className="level-indicator">Level 5th</div>
        <p>Choose one of the following upgrades for your exoskeleton:</p>
        <div className="trait">
          <div className="trait-header">
            <h3>Booster Strike Module</h3>
          </div>
          <p>You integrate thrusters into your exoskeleton, enabling a powerful charging attack. As an action, you move in a straight 30-foot line, without provoking opportunity attacks. You may attack creatures along the path, dealing an additional 2d6 force damage and forcing them to succeed on a Strength saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus) or be knocked prone. If interrupted, you take 1d6 force damage per 10 feet not completed.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Energy Shielding</h3>
          </div>
          <p>By channeling arcane energy through your exoskeleton, you gain resistance to one damage type of your choice (acid, cold, fire, lightning, or thunder). This shielding manifests as a faint, shimmering aura around your exoskeleton, deflecting incoming energy. The chosen resistance can be recalibrated during a long rest as you fine-tune your drone's energy field to match anticipated threats.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Phase Dash</h3>
          </div>
          <p>As a bonus action, teleport to an unoccupied space you can see within 90 feet. After teleporting, you gain advantage on your next melee or ranged attack before the end of your turn. You can use this feature a number of times equal to your proficiency bonus, and all expended uses recharge after a long rest.</p>
        </div>
      </div>

      {/* --- Adaptive Systems Level 9 Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Adaptive Systems</h2>
        <div className="level-indicator">Level 9th</div>
        <p>Choose one of the following upgrades for your exoskeleton:</p>
        <div className="trait">
          <div className="trait-header">
            <h3>Adaptive Defense</h3>
          </div>
          <p>Your exoskeleton's advanced sensors and shielding systems dynamically adjust to protect against immediate threats. As a reaction, you can gain immunity to one damage type of your choice until the start of your next turn. The applicable damage types are: acid, cold, fire, force, lightning, necrotic, poison, psychic, radiant, and thunder. When this ability is activated, the exoskeleton emits a brief flash of energy, visually displaying the adjustment in a shimmer of matching color (e.g., blue for cold, red for fire). You can use this feature a number of times equal to your proficiency bonus, recharging after a long rest.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Overdrive Mode</h3>
          </div>
          <p>As a bonus action, activate Overdrive Mode for 1 minute. Gain an additional action per turn for specific actions (Attack, Dash, Disengage, Hide, Use an Object). After Overdrive ends, succeed on a DC 15 Constitution saving throw or gain one level of exhaustion. Usable a number of times equal to your proficiency bonus, recharging after a long rest.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Flight System</h3>
          </div>
          <p>Your exoskeleton is now equipped with an advanced propulsion unit, granting you full flight capabilities. You gain a flying speed of 30 feet and can hover. This flight system has no time limit and is always active, allowing you to navigate difficult terrain and maintain a tactical advantage both in and out of combat. The propulsion system emits a faint, controlled hum of energy, adding an elegant, futuristic flair to your movements.</p>
        </div>
      </div>

      {/* --- Integrated Arsenal Level 15 Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Integrated Arsenal</h2>
        <div className="level-indicator">Level 15th</div>
        <p>Choose one of the following upgrades for your exoskeleton:</p>
        <div className="trait">
          <div className="trait-header">
            <h3>Energy Overload</h3>
          </div>
          <p>Release a devastating force blast in a 30-foot cone of force energy. Each creature must make a Dexterity saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus), taking 6d10 force damage on a failed save or half on success. Usable a number of times equal to your proficiency bonus, recharging after a short or long rest.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Force Barrier</h3>
          </div>
          <p>As a bonus action, gain temporary hit points equal to twice your level for 1 minute. Creatures hitting you with melee attacks take force damage equal to your Intelligence modifier. Usable a number of times equal to your proficiency bonus, recharging after a long rest.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Gravitic Inversion Field</h3>
          </div>
          <p>As an action, your exoskeleton generates a localized field of inverted gravity, replicating the effects of the reverse gravity spell. This field affects a 30-foot-radius, 30-foot-high cylinder centered on you. While within the field, you can move and act normally, unaffected by the inverted gravity. All other creatures and objects in that area that aren't somehow anchored to the ground fall upward and reach the top of the cylinder. A creature can make a Dexterity saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus) to grab a fixed object it can reach, thus avoiding the fall upward. If a ceiling or an anchored object is encountered in this upward fall, creatures and objects strike it just as they would during a normal downward fall. If an affected creature or object reaches the cylinder's top without striking anything, it hovers there for the duration, falling downward when the effect ends after 1 minute. You can use this feature a number of times equal to your proficiency bonus, recharging after a long rest.</p>
        </div>
      </div>

      {/* --- Masterful Integration Level 18 Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Masterful Integration</h2>
        <div className="level-indicator">Level 18th</div>
        <p>Choose one of the following upgrades for your exoskeleton:</p>
        <div className="trait">
          <div className="trait-header">
            <h3>Permanent Integration</h3>
          </div>
          <p>Your exoskeleton no longer has an active time limit and enhances your physical capabilities. You gain a +4 bonus to Strength and a +2 bonus to Dexterity. Features that specify limited uses or specific time limits still apply and are recharged as specified.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Temporal Core</h3>
          </div>
          <p>Your exoskeleton integrates advanced temporal manipulation, permanently granting you the effects of the haste spell. Additionally, as an action, you can activate a Temporal Field for 1 minute, centered on you with a 30-foot radius. Within this field, you and allies gain the effects of haste, while enemies must succeed on a Wisdom saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus) at the start of their turn or be affected by the slow spell for that turn. You can use this ability a number of times equal to your proficiency bonus, recharging after a long rest.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Second Suit Deployment</h3>
          </div>
          <p>You gain the ability to create and maintain a second exoskeleton suit, which can be worn by another party member or piloted remotely by you within a 1-mile range. This suit has the following features and mechanics:</p>
          <p><strong>Customization and Reprogramming:</strong> During a long rest, you can reprogram the second suit, altering its abilities to suit the current needs of the party. The suit can take on the functionality of the Path of the Exoskeleton, gaining the following benefits:</p>
          <ul>
            <li>The 3rd-level abilities of Path of the Exoskeleton</li>
            <li>One 5th-level ability of Path of the Exoskeleton</li>
            <li>One 9th-level ability of Path of the Exoskeleton</li>
          </ul>
          <p>This secondary suit is a marvel of engineering and arcane craftsmanship, visually distinct from your own. When worn by another, it enhances their combat or utility performance, glowing faintly with your magical energy. When piloted remotely, the suit moves with mechanical precision, acting as an extension of your will and responding to commands instantly.</p>
        </div>
      </div>

      {/* --- Path of the Weaponized Drone Description (Matches Structure) --- */}
      <div className="content-block">
        <h2>Path of the Weaponized Drone</h2>
        <p>
          The weaponized drone is a versatile construct designed for both precision and destruction, seamlessly merging arcane and mechanical ingenuity. Once activated, the drone becomes a permanent fixture of your arsenal, requiring only occasional maintenance during downtime to remain operational. Shifting fluidly between combat roles, it can be a ranged, melee weapon, or a flying weaponized drone. Each enhancing your effectiveness in battle. Its sleek, adaptive design allows it to dynamically respond to your commands, with glowing energy conduits and intricate mechanisms radiating a blend of artistry and lethality. Whether wielding it as a weapon or deploying it as an autonomous combatant, the drone exemplifies your mastery of arcane engineering.
        </p>
      </div>

      {/* --- Weaponized Drone Creation Level 3 Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Weaponized Drone Creation</h2>
        <div className="level-indicator">Level 3rd</div>
        <p>Choose between:</p>
        <div className="trait">
          <div className="trait-header">
            <h3>Ranged Weapon Form</h3>
          </div>
          <p>The drone transforms into a physical ranged weapon of your choice, such as a pistol, rifle, or crossbow, tailored to your preferences and designed for precision. With advanced targeting systems and arcane energy capacitors, it delivers 1d8 energy damage within a 60-foot range. Each shot crackles with magical energy, illuminating the path of the projectile with a faint glow. Whether a sleek firearm or a magically enhanced crossbow, this weapon is ideal for calculated strikes from afar.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Energy Weapon Form</h3>
          </div>
          <p>The drone transforms into a powerful melee weapon, channeling pure energy into a devastating close-range attack. With advanced energy conduits and arcane amplifiers, it delivers 1d10 energy damage within a 5-foot range. Each strike crackles with magical energy, illuminating the target with a faint glow. Whether a sleek energy blade or a brutal energy maul, this weapon is ideal for precise and powerful strikes in close combat.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Flying Weaponized Drone</h3>
          </div>
          <p>The drone transforms into a lethal aerial combatant, equipped with advanced propulsion systems and precision targeting. With its sleek design and glowing energy conduits, it soars through the air, delivering devastating strikes from above. Whether equipped with energy cannons or precision lasers, this drone is ideal for aerial superiority and tactical strikes, providing unmatched versatility in both offense and reconnaissance.</p>
        </div>
      </div>

      {/* --- Weaponized Drone Stat Block Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Weaponized Drone Stat Block</h2>
        <p><strong>Armor Class:</strong> 17 (Reinforced Plating)</p>
        <p><strong>Hit Points:</strong> 5 + five times your Artificer level (5 + 5 × Artificer level)</p>
        <p><strong>Speed:</strong> 30 ft., fly 30 ft. (hover)</p>
        <p><strong>STR:</strong> 10 (+0) | <strong>DEX:</strong> 14 (+2) | <strong>CON:</strong> 12 (+1) | <strong>INT:</strong> 10 (+0) | <strong>WIS:</strong> 10 (+0) | <strong>CHA:</strong> 6 (-2)</p>
        <p><strong>Saving Throws:</strong> Dexterity +4, Constitution +3 (add proficiency bonus as applicable)</p>
        <p><strong>Skills:</strong> Perception +2 (or +4 if proficient)</p>
        <p><strong>Damage Immunities:</strong> Poison</p>
        <p><strong>Condition Immunities:</strong> Charmed, Exhaustion, Poisoned</p>
        <p><strong>Senses:</strong> Darkvision 60 ft., Passive Perception 12</p>
        <p><strong>Languages:</strong> Understands the languages you speak but cannot speak</p>
        <p><strong>Actions:</strong></p>
        <p style={{ marginLeft: '20px' }}><strong>Multiattack (Level 5 or Higher):</strong> The drone makes two attacks: one with its Ranged Weapon Form and one with its Melee Weapon Form.</p>
        <p style={{ marginLeft: '20px' }}><strong>Ranged Weapon Form:</strong> Energy Bolt. Ranged Weapon Attack: Your spell attack modifier to hit, range 60 ft., one target. Hit: 1d8 + Intelligence modifier fire damage.</p>
        <p style={{ marginLeft: '20px' }}><strong>Melee Weapon Form:</strong> Energy Blade. Melee Weapon Attack: Your spell attack modifier to hit, reach 5 ft., one target. Hit: 1d10 + Intelligence modifier fire damage.</p>
        <p><strong>Features:</strong></p>
        <p style={{ marginLeft: '20px' }}><strong>Tactical Adaptation:</strong> The drone can switch between its Ranged and Melee Weapon Forms as a bonus action on your turn.</p>
        <p style={{ marginLeft: '20px' }}><strong>Integrated Targeting Systems:</strong> Add your proficiency bonus to the drone's attack rolls and damage calculations.</p>
        <p style={{ marginLeft: '20px' }}><strong>Energy Efficiency:</strong> The drone recharges all hit points during a short or long rest.</p>
        <p style={{ marginLeft: '20px' }}><strong>Self-Stabilizing Hover:</strong> The drone hovers up to 10 feet above the ground, ignoring difficult terrain.</p>
        <p style={{ marginLeft: '20px' }}><strong>Remote Control:</strong> The drone obeys your verbal or mental commands within a 120-foot range. Commands are issued as a bonus action.</p>
      </div>

      {/* --- Weaponized Drone Upgrades Level 5 Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Weaponized Drone Upgrades</h2>
        <div className="level-indicator">Level 5th</div>
        <p>Choose one of the following upgrades:</p>
        <div className="trait">
          <div className="trait-header">
            <h3>Shotgun Blast</h3>
          </div>
          <p>Your drone's ranged weapon form can now unleash a powerful blast of energy in a 15-foot cone as an action. Each creature within the cone must make a Constitution saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus). On a failed save, a creature takes 2d6 force damage and is stunned until the end of its next turn. On a successful save, a creature takes half as much damage and is not stunned. This ability uses your drone's ranged weapon form and does not require it to be in that form to use, but if used while in ranged form, the drone forgoes its normal ranged attack for that turn. Once used, the drone must complete a short or long rest before it can be used again.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Melee Discharge</h3>
          </div>
          <p>The melee weapon form can change to any melee weapon you are proficient with. The chosen weapon's damage die is used, and on a critical hit, it releases a 5-foot burst of energy, forcing creatures within the burst to make a Dexterity saving throw or take 1d6 energy damage.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Aerial Bombardment</h3>
          </div>
          <p>The drone can execute an area-of-effect attack, targeting a 10x10 area within 30 feet. Creatures in the area must make a Dexterity saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus) or take 1d6 damage, halved on success.</p>
        </div>
      </div>

      {/* --- Weaponized Drone Upgrades Level 9 Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Weaponized Drone Level 9 Upgrades</h2>
        <div className="level-indicator">Level 9th</div>
        <p>Choose one of the following upgrades:</p>
        <div className="trait">
          <div className="trait-header">
            <h3>Adaptive Energy Matrix</h3>
          </div>
          <p>Add necrotic and radiant damage to available energy types. The drone can now switch its damage type for all its attacks as a bonus action. Choose from: acid, cold, fire, lightning, necrotic, thunder or radiant.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Additional Form</h3>
          </div>
          <p>Gain access to an additional drone form; Bonus Action: The user can expend their bonus action to have their drone assume their second form.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Targeting Beacon</h3>
          </div>
          <p>As a bonus action, you can mark a target within 60 feet with a tracking beacon that lasts for 1 minute or until you lose concentration (as if concentrating on a spell). While the target is marked, you have advantage on all attack rolls made with your drone against the marked target. Additionally, if the marked target attempts to move out of your line of sight, your drone can use its reaction to move up to its full movement speed to maintain line of sight, even if it means provoking opportunity attacks. You can use this ability a number of times equal to your proficiency bonus, regaining all expended uses after a long rest.</p>
        </div>
      </div>

      {/* --- Weaponized Drone Upgrades Level 15 Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Weaponized Drone Level 15 Upgrades</h2>
        <div className="level-indicator">Level 15th</div>
        <p>Choose one of the following upgrades:</p>
        <div className="trait">
          <div className="trait-header">
            <h3>Dual Mode</h3>
          </div>
          <p>Your drone's adaptive systems reach new heights, allowing it to fluidly shift between its ranged and melee weapon forms as a bonus action. This advanced integration of arcane and mechanical engineering ensures that your drone is always ready to respond to the ever-changing dynamics of battle, embodying the perfect blend of versatility and lethality.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Gain a Second Drone</h3>
          </div>
          <p>Your expertise in drone crafting allows you to create and command a second drone, each a marvel of arcane engineering. Choose its form and one ability from the Level 5 and Level 9 lists at creation. This second drone operates independently, showcasing your mastery over technological innovation and enhancing your tactical prowess on the battlefield. Whether used for offense, defense, or support, this second drone is a testament to your growing arsenal of arcane constructs.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Absorption Drone Matrix</h3>
          </div>
          <p>Your drone's energy systems are enhanced with advanced absorption technology. As a reaction, when either the wielder of your drone or the drone itself takes damage from an elemental source (acid, cold, fire, lightning, or thunder), the drone can absorb that energy. The next time the drone makes an attack before the end of your next turn, it deals additional damage equal to the amount of damage absorbed, of the same damage type. This feature can be used a number of times equal to your proficiency bonus per long rest, turning the enemy's own elemental power against them and showcasing the drone's adaptive and resilient nature.</p>
        </div>
      </div>

      {/* --- Weaponized Drone Upgrades Level 18 Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Weaponized Drone Level 18 Upgrades</h2>
        <div className="level-indicator">Level 18th</div>
        <p>Choose one of the following upgrades:</p>
        <div className="trait">
          <div className="trait-header">
            <h3>Ranged Form: Arcane Siege Mode</h3>
          </div>
          <p>Your drone permanently upgrades to function as a devastating ranged weapon. While in this mode, the drone can make ranged weapon attacks. Each attack uses your spell attack modifier for the attack roll, has a range of 300 feet, and deals 4d12 force damage on a hit. These attacks ignore all cover.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Melee Form: Titan Strike Mode</h3>
          </div>
          <p>Your drone enhances your melee capabilities, creating an energy-infused weapon of pure destructive force. For 1 minute, your melee weapon attacks deal an additional 3d10 force damage and cause creatures hit to be pushed back 10 feet. Additionally, on a critical hit, the target must succeed on a Strength saving throw (DC = 8 + your Intelligence modifier + your proficiency bonus) or be knocked prone. This ability can be used a number of times equal to your proficiency bonus, recharging all uses after a long rest.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Autonomous Form: War Machine Mode</h3>
          </div>
          <p>Your drone activates into a fully autonomous war construct. It gains an additional 30 hit points, can make two attacks per turn, and its energy attacks deal 2d10 force damage with an additional 10-foot-radius explosion, for the bombardment feature, that deals 1d10 damage to all creatures within the area. Recharges after a long rest.</p>
        </div>
      </div>

      {/* --- Path of the Toolkit Drone Description (Matches Structure) --- */}
      <div className="content-block">
        <h2>Path of the Toolkit Drone</h2>
        <p>
          The Toolkit Drone specialization transforms your mechanical companion into a versatile and indispensable ally, embodying the pinnacle of utility and adaptability. This drone functions as a support companion, a mobile repair station, and a healer, while also providing assistance in crafting and problem-solving. Designed to act as an extension of your ingenuity, the Toolkit Drone seamlessly integrates into both combat and exploration scenarios. Whether mending wounds, creating essential tools, or shielding allies from harm, this path exemplifies the boundless potential of combining arcane and technological mastery into a single, multifunctional device.
        </p>
      </div>

      {/* --- Mechanics: Kit Points Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Mechanics: Kit Points</h2>
        <p>
          At the beginning of each long rest, you gain a pool of Kit Points equal to your proficiency bonus. These points are spent to activate your drone's abilities, which scale in cost and power as you level up.
        </p>
        <div className="trait">
          <div className="trait-header">
            <h3>Abilities and Kit Point Costs</h3>
          </div>
          <p><strong>Level 3 Abilities (1 Kit Point per use):</strong> Basic functions such as minor healing, minor item creation, or skill assistance.</p>
          <p><strong>Level 5 Abilities (2 Kit Points per use):</strong> Improved support functions such as enhanced repairs or granting temporary bonuses to allies.</p>
          <p><strong>Level 9 Abilities (3 Kit Points per use):</strong> Advanced features such as area-of-effect healing or utility effects like removing conditions.</p>
          <p><strong>Level 15 Abilities (4 Kit Points per use):</strong> Powerful augmentations, including multi-target effects or simultaneous module activations. You can select and use up to two of these abilities per long rest.</p>
        </div>
        <p>
          Kit Points represent the energy and resources required to keep your drone running efficiently. They refresh at the end of a long rest, ensuring you can adapt to challenges each day with renewed capacity. This system ensures strategic use of higher-level abilities, balancing power with versatility.
        </p>
      </div>

      {/* --- Toolkit Drone Creation Level 3 Section (Matches Structure, Includes ALL Items Part 1) --- */}
      <div className="content-block">
        <h2>Toolkit Drone Creation</h2>
        <div className="level-indicator">Level 3rd</div>
        <p>
          At 3rd level, your drone becomes an invaluable companion, capable of adapting to a variety of challenges. Through its advanced modular design, it integrates seamlessly into your adventures, offering a range of transformative capabilities. These starting features highlight the balance of cutting-edge technology and arcane ingenuity, ensuring your drone can aid you in exploration, combat, and problem-solving alike. (Cost: 1 Kit Point per use where applicable)
        </p>
        <div className="trait">
          <div className="trait-header">
            <h3>Level 3 Modular Adaptations (Part 1)</h3>
          </div>
          <p><strong>Hydro-Adaptive Suit:</strong> A sleek, breathable tech suit that enables the wearer to breathe underwater and swim at an enhanced speed of 30 feet, adapting seamlessly to aquatic environments. Duration: Up to 1 hour per use.</p>
          <p><strong>Buoyancy Stabilizer Boots:</strong> High-tech boots that manipulate surface tension, allowing the wearer to walk on water as if it were solid ground. Duration: Up to 1 hour per activation.</p>
          <p><strong>Spatial Compression Storage Unit:</strong> A compact backpack utilizing spatial compression technology to store up to 500 pounds of items (but not exceeding 64 cubic feet in volume), while weighing only 15 pounds. No duration limit.</p>
          <p><strong>Bio-Stabilizer Implant:</strong> A wearable tech device that regulates and fortifies the user's biology, granting immunity to disease and neutralizing poisons within 1 minute of exposure. No duration limit.</p>
          <p><strong>Portable Water Generator:</strong> A moisture-capture device that produces clean, unlimited water at adjustable flow rates (fountain, stream, or geyser), useful for survival or extinguishing fires. Duration: Continuous while activated.</p>
          <p><strong>Escape Assistance Device:</strong> A drone-integrated module that automatically activates to aid the wearer in escaping restraints or bindings, granting advantage on checks made to escape or avoid being grappled. Usable once per hour.</p>
          <p><strong>Luck Optimization Module:</strong> A subtle enhancement chip embedded in the drone that improves reaction timing and decision-making, granting a +1 bonus to all ability checks and saving throws. Continuous effect while active.</p>
          <p><strong>Stealth Field Enhancers:</strong> Noise-dampening tech boots that enhance stealth by granting advantage on Dexterity (Stealth) checks and reducing detection risk in low-light environments. Continuous effect while worn.</p>
          <p><strong>Thermo-Insulated Boots:</strong> Advanced footwear with temperature-regulating technology, providing resistance to cold damage and advantage on checks to traverse icy or snowy terrain. Continuous effect while worn.</p>
          <p><strong>Climbing Assist Modules:</strong> Mechanized gloves with retractable grips and stabilizers that grant a climbing speed equal to your walking speed, allowing effortless scaling of vertical surfaces. Continuous effect while worn.</p>
        </div>
      </div>

      {/* --- More Toolkit Drone Adaptations Level 3 Section (Matches Structure, Includes ALL Items Part 2) --- */}
      <div className="content-block">
        <h2>More Toolkit Drone Adaptations</h2>
        <div className="level-indicator">Level 3rd</div>
        <div className="trait">
          <div className="trait-header">
            <h3>Level 3 Modular Adaptations (Part 2)</h3>
          </div>
          <p><strong>Telescopic Vision Lenses:</strong> Goggles with integrated zoom and clarity enhancements, granting advantage on Wisdom (Perception) checks relying on sight and the ability to spot details up to 1 mile away in clear conditions. Usable for up to 10 minutes per activation.</p>
          <p><strong>Low-Light Optics:</strong> Enhanced visual goggles that amplify ambient light and simulate darkvision up to a range of 60 feet, useful for navigating dim or dark environments. Continuous effect while worn.</p>
          <p><strong>Revealing Scanner Light:</strong> A portable scanning lamp integrated into the drone, which highlights cloaked or hidden entities and objects within a 30-foot radius when activated. Duration: 10 minutes per activation.</p>
          <p><strong>Atmospheric Filtration Unit:</strong> A wearable mask or necklace that filters air and adjusts to extreme environments, granting immunity to harmful gases and breathable air in otherwise uninhabitable conditions. Continuous effect while worn.</p>
          <p><strong>Sonic Pest Controller:</strong> A sonic emitter mounted on the drone that attracts or repels specific creatures (such as rats or insects) within a 30-foot radius, controllable by the user. Usable for up to 1 hour per activation.</p>
          <p><strong>Gravitational Stabilizer Device:</strong> A wearable tech module that negates fall impact by slowing descent, functioning as a continuous feather fall effect. Continuous effect while worn.</p>
          <p><strong>Speed Boosters:</strong> High-performance boosters integrated into mounts or vehicles, enhancing their speed by 20 feet for up to 1 hour per activation. Cooldown: 1 hour between uses.</p>
          <p><strong>Hydro-Breathing Apparatus:</strong> A lightweight, helmet-mounted system that allows the wearer to breathe underwater for up to 1 hour per use. Cooldown: 1 hour between uses.</p>
          <p><strong>Holo-Projector Module:</strong> A wearable drone attachment that generates a realistic holographic disguise, enabling the wearer to appear as another creature or object for up to 1 hour. Cooldown: 1 hour between uses.</p>
          <p><strong>Fog Emission Unit:</strong> A drone-mounted device that creates a dense fog cloud in a 20-foot radius, lasting for 10 minutes. Cooldown: 1 hour between uses.</p>
        </div>
      </div>

      {/* --- Higher Level Toolkit Adaptations Section (Matches Structure, Includes ALL Items) --- */}
      <div className="content-block">
        <h2>Higher Level Toolkit Adaptations</h2>
        {/* --- Level 5 Adaptations --- */}
        <div className="level-indicator">Level 5th</div>
         <p>(Cost: 2 Kit Points per use where applicable)</p>
        <div className="trait">
          <div className="trait-header">
            <h3>Level 5 Adaptations</h3>
          </div>
          <p><strong>Bio-Stabilizer Implant:</strong> A wearable tech device that sets the wearer's Constitution to 19, fortifying their health and resilience. Duration: Continuous effect while worn.</p>
          <p><strong>Strength Assist Belt:</strong> A tech-enhanced belt that grants advantage on Strength (Athletics) checks for grappling, lifting, and shoving. Duration: Continuous effect while worn.</p>
          <p><strong>Aero-Lift Boots:</strong> Boots equipped with retractable thrusters, enabling short-term flight up to a height of 30 feet with a speed of 60 feet. Duration: Up to 10 minutes per activation. Cooldown: 1 hour between uses.</p>
          <p><strong>Auto-Climbing Cable:</strong> A grappling cable integrated into the drone, allowing effortless climbing and anchoring. Duration: Up to 1 hour per use. Cooldown: 1 hour between uses.</p>
          <p><strong>Kinetic Enhancement Boots:</strong> Mobility-enhancing boots that negate movement penalties and triple the wearer's normal jump distance. Duration: Continuous effect while worn.</p>
          <p><strong>Resonance Unlocker:</strong> A compact sonic vibration tool that bypasses mundane locks and disables nonmagical traps. Usable 3 times per long rest.</p>
          <p><strong>Adhesive Neutralizer:</strong> A spray that dissolves adhesives, including magical ones, freeing creatures or objects from entanglement or traps. Usable up to 5 times before refueling. Duration: Instantaneous.</p>
          <p><strong>Charisma Boost Lenses:</strong> Tech-enhanced lenses granting advantage on Charisma (Persuasion) checks and a single use of charm person per day. Duration: Continuous effect for skill bonuses. Cooldown: 24 hours for charm person.</p>
          <p><strong>Cognitive Enhancement Interface:</strong> A wearable device that sets the wearer's Intelligence to 19, sharpening their mental acuity. Duration: Continuous effect while worn.</p>
          <p><strong>Linguistic Decoding Module:</strong> A device that enables the wearer to understand all spoken languages and read any written text. Duration: Continuous effect while worn.</p>
          <p><strong>Energy Reclamation Module:</strong> A compact system that allows recovery of one expended 1st-level spell slot. Usable once per long rest.</p>
          <p><strong>Sonic Manipulator:</strong> A sound-emission device capable of summoning a phantom steed for travel or reconnaissance. Duration: Up to 1 hour per use. Cooldown: 24 hours between uses.</p>
        </div>

        {/* --- Level 9 Adaptations --- */}
        <div className="level-indicator">Level 9th</div>
         <p>(Cost: 3 Kit Points per use where applicable)</p>
        <div className="trait">
          <div className="trait-header">
            <h3>Level 9 Adaptations</h3>
          </div>
          <p><strong>Privacy Shield Module:</strong> A wearable device that prevents magical tracking and divination, shielding the user's location from detection. Duration: Continuous effect while worn.</p>
          <p><strong>Hover Stabilizer Boots:</strong> Tech boots that allow controlled vertical movement up to 20 feet, providing enhanced mobility for exploration or escape. Duration: Up to 10 minutes per activation. Cooldown: 1 hour between uses.</p>
          <p><strong>Spatial Compression Disk:</strong> A portable disk that creates an extra-dimensional storage space capable of holding up to 500 pounds or small creatures. Duration: Continuous while activated.</p>
          <p><strong>Aero-Lift Carpet:</strong> A lightweight, hover-capable rug capable of carrying up to six Medium creatures at a speed of 60 feet. Duration: Continuous while in use.</p>
          <p><strong>Nocturnal Flight Cloak:</strong> A tech-enhanced cloak enabling stealthy flight and transformation into a bat-like form. Duration: Up to 1 hour per activation. Cooldown: 1 hour between uses.</p>
          <p><strong>Visionary Analyzer:</strong> A device that allows remote viewing of distant locations, people, or events. Duration: Up to 10 minutes per use. Cooldown: 24 hours between uses.</p>
          <p><strong>Nano-Nutrient Orb:</strong> A floating device that eliminates the need for food and drink by supplying essential nutrients directly to the user. Duration: Continuous effect while active.</p>
          <p><strong>Alertness Enhancement Orb:</strong> A floating sphere that eliminates surprise conditions and boosts vigilance, granting advantage on initiative rolls. Duration: Continuous effect while active.</p>
          <p><strong>Defensive Buffer Orb:</strong> A floating device projecting an energy shield that grants a +1 bonus to AC. Duration: Continuous effect while active.</p>
          <p><strong>Stellar Attunement Cloak:</strong> A garment granting limited flight, enhanced mobility, and a +1 bonus to AC, as well as shedding bright light in a 30-foot radius. Duration: Continuous effect while worn.</p>
        </div>

        {/* --- Level 15 Adaptations --- */}
        <div className="level-indicator">Level 15th</div>
         <p>(Cost: 4 Kit Points per use where applicable)</p>
        <div className="trait">
          <div className="trait-header">
            <h3>Level 15 Adaptations</h3>
          </div>
          <p><strong>Plane Travel Module:</strong> A sophisticated device enabling the wearer to cast plane shift once per day, allowing instantaneous travel across planes of existence. Cooldown: 24 hours between uses.</p>
          <p><strong>Enhanced Strength Regulator:</strong> A wearable device that sets the wearer's Strength score to 25, enabling feats of extraordinary physical power. Duration: Continuous effect while worn.</p>
          <p><strong>Speed Optimization Unit:</strong> Advanced tech boots that allow the wearer to take the Dash action as a bonus action, doubling their speed. Duration: Up to 10 minutes per activation. Cooldown: 1 hour between uses.</p>
          <p><strong>Stealth Integration Cloak:</strong> A high-tech cloak that renders the wearer invisible while worn, providing unparalleled stealth capabilities. Duration: Up to 1 hour per activation. Cooldown: 1 hour between uses.</p>
          <p><strong>Force Barrier Generator:</strong> A compact device that creates a cube-shaped barrier to block physical and magical attacks. Duration: Up to 10 minutes per use. Cooldown: 1 hour between uses.</p>
          <p><strong>Spatial Relocation Helm:</strong> A wearable helm enabling the wearer to cast teleport once per day, allowing instantaneous travel within the same plane. Cooldown: 24 hours between uses.</p>
          <p><strong>Energy Storage Orb:</strong> A floating device capable of absorbing spells cast at the wearer, storing their energy for later use. Usable up to 3 times per day.</p>
          <p><strong>Leadership Enhancement Orb:</strong> A sphere that hovers around the user, granting a +2 bonus to Charisma checks and inspiring nearby allies, giving them advantage on saving throws against being frightened. Duration: Continuous effect while active.</p>
        </div>
      </div>

      {/* --- Dual Drone Deployment Level 18 Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Dual Drone Deployment</h2>
        <div className="level-indicator">Level 18th</div>
        <p>
          At 18th level, your mastery over technological adaptation reaches its pinnacle, allowing you to integrate a second drone into your arsenal. This feature grants unparalleled flexibility and utility, elevating your role as a battlefield strategist and problem-solver.
        </p>
        <div className="trait">
          <div className="trait-header">
            <h3>Dual Drone Capabilities</h3>
          </div>
          <p><strong>Dual Drone Deployment:</strong> You can now deploy and control two drones simultaneously. Each drone functions as a fully independent entity, and you can transform both into separate items from your available list, assign one or both to allies, or keep both for your own use. This allows for maximum adaptability in diverse scenarios.</p>
          <p><strong>Independent Transformation:</strong> Each drone can be transformed into a different tech-based item at the same time. The transformation of one drone does not affect the other, providing the flexibility to cover multiple needs within the same encounter or exploration scenario.</p>
          <p><strong>Action Economy:</strong> Controlling two drones does not require additional action expenditure. You can command both drones as part of the same bonus action or interaction (as relevant to their current form).</p>
          <p><strong>Resource Sharing:</strong> Both drones draw from your shared Kit Point pool. Deploying two drones simultaneously requires careful resource management, as each transformation or activation consumes Kit Points from the same reservoir.</p>
          <p><strong>Tactical Coordination:</strong> Your drones can operate independently within a range of 60 feet, enabling you to strategically position them for optimal effect. Whether enhancing allies, creating utility effects, or supporting with unique transformations, the dual-drone system ensures you are always prepared for any situation.</p>
        </div>
      </div>

      {/* --- Roleplaying a Dronewright Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Roleplaying a Dronewright</h2>
        <p>
          As a Dronewright, your character embodies the perfect synthesis of innovation and arcane mastery. Whether your drone is a steampunk automaton, a sleek futuristic construct, or a rustic, magic-infused device, its design reflects your ingenuity and creativity. Your path is one of constant experimentation, discovery, and adaptation. You might view your drone as a partner, a reflection of yourself, or a tool to achieve a higher purpose.
        </p>
        <div className="trait">
          <div className="trait-header">
            <h3>Personality Traits</h3>
          </div>
          <p><strong>Inventive:</strong> You are always tinkering, thinking of new ways to improve your creations.</p>
          <p><strong>Methodical:</strong> You approach problems with a calculated and logical mindset.</p>
          <p><strong>Enthusiastic:</strong> You have a genuine excitement for technology and magic, often getting lost in your work.</p>
          <p><strong>Driven:</strong> You pursue perfection, striving to make your creations more efficient and versatile.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Ideals</h3>
          </div>
          <p><strong>Progress:</strong> Technology and magic together can solve any problem.</p>
          <p><strong>Freedom:</strong> Innovation thrives when unrestricted by tradition or rules.</p>
          <p><strong>Collaboration:</strong> Working with others often leads to breakthroughs.</p>
          <p><strong>Legacy:</strong> You aim to leave your mark on the world through your inventions.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Bonds</h3>
          </div>
          <p><strong>Creator's Bond:</strong> Your drone is more than a tool; it's a companion you would do anything to protect.</p>
          <p><strong>Mentorship:</strong> A mentor inspired you to pursue the Dronewright path, and you aim to honor their teachings.</p>
          <p><strong>Discovery:</strong> A significant technological or magical discovery fuels your quest for knowledge.</p>
          <p><strong>Protector:</strong> You feel responsible for defending the balance between technology and nature.</p>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Flaws</h3>
          </div>
          <p><strong>Obsession:</strong> You can become so focused on your projects that you neglect your surroundings.</p>
          <p><strong>Hubris:</strong> You believe your intellect makes you superior to others, leading to overconfidence.</p>
          <p><strong>Isolationist:</strong> Your fascination with your work can make you detached from people.</p>
          <p><strong>Risk-Taker:</strong> You often push your creations to their limits, regardless of the danger.</p>
        </div>
      </div>

      {/* --- Equipment Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Equipment</h2>
        <p>
          As a Dronewright, your equipment reflects your role as an inventor and adventurer. Consider carrying items that enhance your creativity and efficiency.
        </p>
        <ul>
          <li><strong>Artisan's Tools:</strong> Essential for crafting and maintaining your drones. Choose a set based on your drone's aesthetic (e.g., tinker's tools, smith's tools).</li>
          <li><strong>Notebook:</strong> A journal for recording your designs, equations, and theories.</li>
          <li><strong>Component Pouch:</strong> Filled with magical and mechanical parts to repair and enhance your drone.</li>
          <li><strong>Arcane Focus:</strong> A device integrated into your drone, such as a crystal capacitor or a miniature energy core.</li>
          <li><strong>Protective Gear:</strong> Goggles, gloves, and a reinforced apron for safety during experiments.</li>
          <li><strong>Backup Modules:</strong> Spare parts for quick field repairs.</li>
        </ul>
        <p>
          Starting equipment might include:
        </p>
        <ul>
          <li>A basic drone prototype tailored to your specialization.</li>
          <li>A set of artisan's tools of your choice.</li>
          <li>Light armor, a simple weapon, and a crossbow or firearm for self-defense.</li>
          <li>An adventurer's pack customized with compartments for your inventions.</li>
        </ul>
      </div>

      {/* --- Multiclassing Section (Matches Structure) --- */}
      <div className="content-block">
        <h2>Multiclassing</h2>
        <p>
          If you choose to multiclass, consider how additional skills and abilities might enhance your drone and your technological pursuits.
        </p>
        <div className="trait">
          <div className="trait-header">
            <h3>Complementary Classes</h3>
          </div>
          <p><strong>Wizard:</strong> The Wizard's focus on arcane study complements your technical expertise. Choose the School of Transmutation or Abjuration for added utility and durability for both you and your drone.</p>
          <p><strong>Rogue:</strong> The Rogue's stealth and precision make you an even more formidable tactician. Combine the Arcane Trickster subclass with your drones for enhanced infiltration and sabotage.</p>
          <p><strong>Fighter:</strong> Take the Battle Master archetype to gain combat maneuvers, improving your strategic options in battle. Your drone can act as an extension of these tactics.</p>
          <p><strong>Sorcerer:</strong> A Sorcerer's innate magical abilities can amplify your technological prowess. The Clockwork Soul subclass is particularly thematic, emphasizing order and innovation.</p>
          <p><strong>Cleric:</strong> Combining the Artificer with the Forge Domain Cleric enhances your crafting abilities, allowing you to create and bless your mechanical creations with divine power.</p>
        </div>
      </div>

      {/* --- Image Modal (Unchanged) --- */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img
                src={dronewrightImage}
                alt="Dronewright Full" // Changed alt text slightly
                className="modal-image"
              />
              <button
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a
                href={dronewrightImage}
                download="Dronewright.jpg" // Adjusted filename slightly
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesDronewright;