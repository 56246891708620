import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false); // Start with no loading
  const [token, setToken] = useState(localStorage.getItem('auth_token'));
  const [authError, setAuthError] = useState(null);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL

  // No automatic token check on startup - only fetch profile when explicitly requested
  // This prevents the infinite loop of authentication checks

  const fetchUserProfile = async () => {
    // Only attempt if we have a token
    if (!token) {
      setLoading(false);
      return null;
    }
    
    try {
      console.log('Fetching user profile');
      
      const response = await fetch(`${API_URL}/profile`, {
        method: 'GET',
        headers: {
          'x-auth-token': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      console.log('Profile response status:', response.status);

      if (response.ok) {
        const userData = await response.json();
        console.log('User data received:', userData);
        setCurrentUser(userData);
        setAuthError(null);
        setLoading(false);
        return userData;
      } else {
        // Only logout on authentication errors (401, 403)
        if (response.status === 401 || response.status === 403) {
          console.error('Authentication error:', response.status);
          logoutInternal();
        } else {
          console.error('API error:', response.status);
          setAuthError(`Server error (${response.status}). Please try again later.`);
        }
        setLoading(false);
        return null;
      }
    } catch (error) {
      console.error('Error fetching user profile:', error.message);
      setAuthError('Network error. Please check your connection and try again.');
      setLoading(false);
      return null;
    }
  };

  // Initialize auth once on first load - no retries, no recursion
  useEffect(() => {
    const initAuth = async () => {
      // Only try to fetch the profile if we have a token
      if (token) {
        setLoading(true);
        await fetchUserProfile();
      }
    };
    
    initAuth();
    // Empty dependency array - run only on mount
  }, []);

  const login = async (email, password) => {
    try {
      setAuthError(null);
      setLoading(true);
      
      console.log('Attempting login for:', email);
      
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      // Handle no content response
      if (response.status === 204) {
        setLoading(false);
        throw new Error('Login failed: No content received');
      }

      const data = await response.json();
      
      if (!response.ok) {
        setLoading(false);
        throw new Error(data.message || 'Login failed');
      }

      console.log('Login successful, token received');
      
      // First update the user data
      setCurrentUser(data.user);
      
      // Store token in localStorage
      localStorage.setItem('auth_token', data.token);
      
      // Update token state last
      setToken(data.token);
      
      // Finally clear loading state
      setLoading(false);
      
      return data.user;
    } catch (error) {
      console.error('Login error:', error.message);
      setLoading(false);
      throw error;
    }
  };

  const signup = async (displayName, email, password) => {
    try {
      setAuthError(null);
      setLoading(true);
      
      console.log('Attempting signup for:', email);
      
      const response = await fetch(`${API_URL}/register`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          displayName, 
          email, 
          password,
          role: 'commoner' // Default role for new users
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        setLoading(false);
        throw new Error(data.message || 'Signup failed');
      }

      console.log('Signup successful, setting data');
      
      // First set the user data
      setCurrentUser(data.user);
      
      // Store token in localStorage
      localStorage.setItem('auth_token', data.token);
      
      // Update token state last
      setToken(data.token);
      
      // Finally clear loading state
      setLoading(false);
      
      return data.user;
    } catch (error) {
      console.error('Signup error:', error.message);
      setLoading(false);
      throw error;
    }
  };

  // Internal logout function - doesn't trigger state updates that cause re-renders
  const logoutInternal = () => {
    localStorage.removeItem('auth_token');
    setToken(null);
    setCurrentUser(null);
    setAuthError(null);
  };

  // External logout function - the one exposed to components
  const logout = () => {
    console.log('Logging out user');
    logoutInternal();
    setLoading(false);
  };

  const value = {
    currentUser,
    loading,
    authError,
    login,
    logout,
    signup
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;