// This utility module is responsible for loading and providing access to Veiled Ascension path data

// Import all path data files
import firstbornData from '../data/veiledpaths/firstbornsoulboundcovenant.json';
import caelumbrisData from '../data/veiledpaths/caelumbrisshroudedcatalyst.json';
import librisData from '../data/veiledpaths/librisknowledge.json';
import nebulaData from '../data/veiledpaths/nebulamistwalker.json';
import queenMabData from '../data/veiledpaths/queenmabfrozenveil.json';
import queenTitaniaData from '../data/veiledpaths/queentitaniawildgrowth.json';
import sacrathorData from '../data/veiledpaths/sacrathordominion.json';
import unboundData from '../data/veiledpaths/unbound.json';
import valdisData from '../data/veiledpaths/valdisjustice.json';
import wildscarData from '../data/veiledpaths/wildscaremberfury.json';

// Map of all path data
const pathDataMap = {
  firstbornsoulboundcovenant: firstbornData,
  caelumbrisshroudedcatalyst: caelumbrisData,
  librisknowledge: librisData,
  nebulamistwalker: nebulaData,
  queenmabfrozenveil: queenMabData,
  queentitaniawildgrowth: queenTitaniaData,
  sacrathordominion: sacrathorData,
  unbound: unboundData,
  valdisjustice: valdisData,
  wildscaremberfury: wildscarData
};

/**
 * Get path data by path ID
 * @param {string} pathId - The ID of the path to retrieve
 * @returns {object|null} - The path data or null if not found
 */
export const getPathData = (pathId) => {
  return pathDataMap[pathId] || null;
};

/**
 * Get the first path from a path data object
 * @param {object} pathData - The path data object containing a paths array
 * @returns {object|null} - The first path or null if not found
 */
export const getFirstPath = (pathData) => {
  if (pathData && pathData.paths && pathData.paths.length > 0) {
    return pathData.paths[0];
  }
  return null;
};

/**
 * Get all available path IDs
 * @returns {string[]} - Array of all available path IDs
 */
export const getAllPathIds = () => {
  return Object.keys(pathDataMap);
};

/**
 * Transform raw path data into the format expected by components
 * @param {object} path - Raw path data
 * @returns {object} - Transformed path data
 */
export const transformPathData = (path) => {
  return {
    id: path.name.toLowerCase().replace(/[^a-z0-9]/g, '-'),
    title: path.name,
    category: 'Path',
    description: path.description || 'This path has been revealed to you.',
    attributes: {
      ...(path.domain && { Domain: path.domain.name }),
      ...(path.boon && { Boon: path.boon.name }),
    },
    additionalInfo: path.additional_information,
    levels: path.levels || {}
  };
};

export default {
  getPathData,
  getFirstPath,
  getAllPathIds,
  transformPathData,
  pathDataMap
}; 