import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import wildscarImage from '../../assets/images/deities/bloodaxe.jpg';
import './DeityPathPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const bloodDrip = keyframes`
  0% { transform: scaleY(0); opacity: 0; }
  50% { transform: scaleY(1); opacity: 0.6; }
  100% { transform: scaleY(0); opacity: 0; }
`;

const wrathPulse = keyframes`
  0%, 100% { 
    text-shadow: 0 0 30px #FF0000, 0 0 50px #FF0000;
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.3);
  }
  50% { 
    text-shadow: 0 0 20px #8B0000, 0 0 35px #8B0000;
    box-shadow: 0 0 30px rgba(139, 0, 0, 0.5);
  }
`;

const liquidBorder = keyframes`
  0% {
    border-image-source: linear-gradient(45deg, #8B0000, #FF0000 45%, #8B0000);
    box-shadow: 
      0 0 20px rgba(139, 0, 0, 0.3),
      inset 0 0 30px rgba(139, 0, 0, 0.2);
  }
  50% {
    border-image-source: linear-gradient(45deg, #FF0000, #8B0000 55%, #FF0000);
    box-shadow: 
      0 0 25px rgba(255, 0, 0, 0.4),
      inset 0 0 35px rgba(255, 0, 0, 0.3);
  }
  100% {
    border-image-source: linear-gradient(45deg, #8B0000, #FF0000 45%, #8B0000);
    box-shadow: 
      0 0 20px rgba(139, 0, 0, 0.3),
      inset 0 0 30px rgba(139, 0, 0, 0.2);
  }
`;

const emberGlow = keyframes`
  0% { background-position: 0% 0%; }
  50% { background-position: 100% 0%; }
  100% { background-position: 0% 0%; }
`;

const fireFlicker = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.8; }
`;

const fireRise = keyframes`
  0% { transform: translateY(100%) scaleY(0); opacity: 0; }
  50% { transform: translateY(-50%) scaleY(1); opacity: 1; }
  100% { transform: translateY(-100%) scaleY(0); opacity: 0; }
`;

const StyledEmberFuryPage = styled.div`
  .deity-path-title h1 {
    font-size: 4rem;
    background: linear-gradient(45deg, #FF0000, #FF4500, #8B0000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 4px;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 
        linear-gradient(90deg,
          transparent,
          rgba(255, 0, 0, 0.2),
          rgba(139, 0, 0, 0.2),
          transparent
        );
      background-size: 200% 100%;
      animation: ${emberGlow} 3s linear infinite;
      z-index: -1;
    }

    &::after {
      content: '🔥';
      position: absolute;
      top: -0.5em;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.5em;
      animation: ${fireFlicker} 2s ease-in-out infinite;
    }
  }

  .content-block, .trait {
    background: rgba(0, 0, 0, 0.8);
    border: 3px solid transparent;
    border-image-slice: 1;
    border-image-source: linear-gradient(45deg, #8B0000, #FF0000);
    box-shadow: 
      0 0 20px rgba(139, 0, 0, 0.3),
      inset 0 0 30px rgba(139, 0, 0, 0.2);
    transition: all 0.5s ease;
    position: relative;
    overflow: hidden;
    animation: ${liquidBorder} 4s ease-in-out infinite;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        linear-gradient(45deg,
          rgba(255, 0, 0, 0.05) 0%,
          rgba(139, 0, 0, 0.05) 50%,
          rgba(255, 69, 0, 0.05) 100%
        );
      pointer-events: none;
      filter: blur(2px);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: 
        repeating-linear-gradient(
          to right,
          transparent 0%,
          transparent 20%,
          rgba(139, 0, 0, 0.15) 20%,
          rgba(139, 0, 0, 0.15) 21%,
          transparent 21%,
          transparent 100%
        );
      mask-image: linear-gradient(to bottom, transparent, black);
      animation: ${bloodDrip} 3s infinite;
      opacity: 0.3;
      filter: blur(1px);
    }

    &:hover {
      border-image-source: linear-gradient(45deg, #FF0000, #8B0000);
      box-shadow: 
        0 0 30px rgba(255, 0, 0, 0.4),
        inset 0 0 40px rgba(139, 0, 0, 0.3);
      transform: translateY(-2px);

      &::after {
        opacity: 0.5;
      }
    }
  }

  h2 {
    color: #FF0000;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #8B0000, #FF0000, #8B0000);
      animation: ${wrathPulse} 3s infinite;
    }
  }

  h3 {
    background: linear-gradient(45deg, #FF0000, #FF4500);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5rem;
    margin: 1rem 0;
    transition: all 0.3s ease;
    
    &:hover {
      text-shadow: 
        0 0 10px rgba(255, 0, 0, 0.5),
        0 0 20px rgba(139, 0, 0, 0.3);
    }
  }

  p {
    color: #FFA07A;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(255, 160, 122, 0.5);
  }

  strong {
    background: linear-gradient(45deg, #FF0000, #FF4500);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    padding: 0 3px;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 0, 0, 0.1);
      transform: skew(-15deg);
      z-index: -1;
    }
  }

  ul li {
    color: #FFA07A;
    position: relative;
    
    &::before {
      content: '🗡️';
      color: #FF0000 !important;
      text-shadow: 0 0 5px rgba(139, 0, 0, 0.5);
      margin-right: 10px;
    }

    &:hover::before {
      content: '⚔️';
    }
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #FF0000, #8B0000);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 
        0 0 10px rgba(255, 0, 0, 0.5),
        0 0 20px rgba(139, 0, 0, 0.3);
    }
  }

  .requirements-section {
    border-image: linear-gradient(45deg, #FF0000, #8B0000) 1;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        repeating-linear-gradient(
          45deg,
          transparent,
          transparent 10px,
          rgba(255, 0, 0, 0.02) 10px,
          rgba(139, 0, 0, 0.02) 20px
        );
      pointer-events: none;
    }
  }

  .veiled-paths h2 {
    background: linear-gradient(45deg, #FF0000, #FF4500);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    
    &::after {
      display: none;
    }
  }

  .veiled-link {
    color: #FFA07A !important;
    transition: all 0.3s ease;

    &:hover {
      background: linear-gradient(45deg, #FF0000, #FF4500);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0 0 8px rgba(255, 0, 0, 0.4);
    }
  }

  .image-modal {
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);

    .modal-image {
      border: 2px solid #FF0000;
      box-shadow: 0 0 30px rgba(255, 0, 0, 0.3);
    }

    .modal-close, .modal-download {
      background: rgba(139, 0, 0, 0.8);
      border: 1px solid #FF0000;
      color: #FFA07A;
      
      &:hover {
        background: rgba(255, 0, 0, 0.8);
        box-shadow: 0 0 15px rgba(255, 0, 0, 0.5);
      }
    }
  }
`;

const WildscarEmberFury = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullImage, setShowFullImage] = useState(false);

  // The sequence of pages for navigation - following dropdown menu order
  const pageSequence = [
    '/deities/caelumbris/shadowed',
    '/deities/firstborn/soulbound',
    '/deities/libris/knowledge',
    '/deities/nebula/mistwalker',
    '/deities/queenmab/frozenveil',
    '/deities/queen-titania/wildgrowth',
    '/deities/sacrathor/dominion',
    '/deities/unbound',
    '/deities/valdis/justice',
    '/deities/wildscar/emberfury'
  ];

  return (
    <StyledEmberFuryPage className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={wildscarImage} 
          alt="Wildscar: Ember Fury Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Wildscar: The Path of Ember Fury</h1>
        </div>
      </div>

      <div className="content-block requirements-section">
        <div className="edicts">
          <h2>Edicts</h2>
          <ul>
            <li>Embrace the primal fury within, giving in to its destructive urges and reveling in the chaos it unleashes.</li>
            <li>Express your wrath without restraint, becoming a force of pure destruction that obliterates anything in its path.</li>
            <li>Savor the thrill of the fight, reveling in the bloodlust and the raw power that surges through your veins as you unleash your fury.</li>
          </ul>
        </div>
        
        <div className="requirements">
          <h2>Requirements</h2>
          <ul>
            <li>Engage in combat or acts of wanton destruction whenever possible, allowing your fury to flow freely and annihilate anything that stands in your way.</li>
            <li>Never back down from a fight, no matter the odds or the consequences. Embrace every opportunity to unleash your unbridled rage.</li>
            <li>Surrender to the bloodlust, reveling in the visceral thrill of combat and the raw power that surges through your being as you unleash your fury.</li>
          </ul>
        </div>
      </div>

      <div className="content-block dark-block">
        <h2 className="blue-text">Wildscar: Ember Fury</h2>
        <p>
          Following the path of Ember Fury imbues warriors with the raw, unrelenting energy of primal chaos. They channel this ferocity to unleash devastating power, becoming both destroyers and protectors on the battlefield. Wildscar's chosen embody the unbridled rage of the elements, a force of nature driven by instinct and passion. Their journey is one of embracing chaos, harnessing its might to dominate their surroundings and ignite the fires of conflict.
        </p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Wildscar: The Primal Fury</h3>
        </div>
        <p>
          Wildscar represents the raw, untamed fury of primal chaos, a force as destructive as it is empowering. She manifests as the embodiment of rage, her presence a fiery catalyst that ignites conflict and passion in equal measure. Warriors who invoke Wildscar's name do not seek guidance; they seek power, embracing the primal instinct that drives them to glory or ruin.
        </p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Wildscar's Domain: The Embered Heart</h3>
        </div>
        <p>
          The realm of Wildscar is a roiling storm of fire and rage. Red mist curls through ashen plains, and rivers of molten rock carve paths through jagged landscapes. This domain is a crucible of fury where the strongest thrive, and the weak are consumed. Every ember and every flame is a reflection of Wildscar's unbridled passion, and the echoes of battle rage eternal across the land. The air hums with tension, vibrating with the cries of warriors who have embraced the chaos and those who fell to it.
        </p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>LEVEL 3: EMBERHEART AWAKENING</h3>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Primal Resistance</h3>
          </div>
          <p>
            The embers of chaos awaken within you, flickering and surging to meet the challenges ahead. The raw energy of Wildscar courses through your body, shaping your resilience to the devastation that may come.
          </p>
          <p>
            Choose one damage type—piercing, slashing, bludgeoning, fire, cold, lightning, acid, radiant, or necrotic—to gain resistance against until your next long rest.
          </p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Rage Transformation</h3>
          </div>
          <p>
            Your form twists and expands as the essence of Wildscar overtakes you. A crimson mist erupts from your skin, the ground beneath your feet cracking from the sheer force of your fury. Your very presence radiates an unrelenting pressure, a warning to all who would stand in your path.
          </p>
          <p>
            Up to your proficiency bonus times per long rest, you can enter a rage as a bonus action, lasting for 1 minute or until you do not attack or are not attacked for 2 consecutive rounds. If the rage ends early due to inaction, you take the remaining rounds as damage, including the two rounds of not attacking or being attacked.
          </p>
          <p>During this rage:</p>
          <ul className="specializations-list">
            <li>Your size increases by 1 category, and your Strength, Constitution, and Dexterity are increased by half your proficiency bonus, rounded down.</li>
            <li>Your movement speed increases by 5 feet per 2 levels.</li>
            <li>You gain resistance to piercing, slashing, bludgeoning, fire, cold, lightning, acid, radiant, necrotic, and psychic damage.</li>
            <li>You have Advantage on Strength checks and Strength saving throws.</li>
          </ul>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>LEVEL 5: EMBER RESILIENCE</h3>
        </div>
        <p>+4 bonus to Constitution.</p>

        <div className="trait">
          <div className="trait-header">
            <h3>Primal Eruption</h3>
          </div>
          <p>
            With a guttural roar, you expel the burning essence of Wildscar, a tide of crimson mist cascading outward. Those caught within the wave feel their minds slip into a battle frenzy, their vision tinged red as their bodies act on primal instinct.
          </p>
          <p>
            Release a burst of red mist in a 15-foot radius. Creatures within must make a Wisdom saving throw (DC = 8 + Constitution modifier + proficiency bonus). On a failed save, they become enraged and must attack the nearest creature on their next turn. The psychic damage dealt is 2d8. Once per Rage Transformation.
          </p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Ember's Endless Fury</h3>
          </div>
          <p>
            When you bring a creature to 0 HP, a red mist erupts in a 10-foot radius. Creatures within must make a Wisdom saving throw or be slowed until your next turn. You gain the effects of Haste until the start of your next turn.
          </p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>LEVEL 10: BERSERKING FURY</h3>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Reactive Fire</h3>
          </div>
          <p>
            When attacked by a melee attack, the attacker must make a Dexterity saving throw (DC = 8 + Constitution modifier + proficiency bonus) or take 1d6 fire damage (half damage on a successful save).
          </p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Eruption of the Red Mist</h3>
          </div>
          <p>
            As your vitality wanes, the fury within you reaches its peak, boiling over into a violent release. When you drop below 50% HP while raging, your body becomes unstable, combusting into a roiling cloud of red mist. This explosion engulfs all creatures within a 15-foot radius, forcing them to make a Dexterity saving throw (DC = 8 + Constitution modifier + proficiency bonus). Those who fail take 8d8 fire damage, while those who succeed take half as much. Your presence momentarily vanishes into the infernal cloud, only for you to reform at the beginning of your next turn anywhere within the mist's radius, emerging anew from the embers of destruction. This ability can be used once per rage.
          </p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>LEVEL 15: AVATAR OF WRATH</h3>
        </div>
        <p>
          The embodiment of Wildscar's fury, you become a living tempest of destruction. Your presence alone is enough to send lesser warriors fleeing, your form wreathed in the eternal fire of your wrath. The battlefield trembles beneath your steps, and your enemies know despair as they gaze upon the unstoppable force you have become.
        </p>
        <ul className="specializations-list">
          <li><strong>Unbreakable Mind:</strong> You become immune to charm, fear, domination, confusion, madness, and all mind-affecting effects.</li>
          <li><strong>Infernal Strikes:</strong> Your melee attacks deal an additional 1d8 fire and 1d8 psychic damage.</li>
          <li><strong>Endless Fury:</strong> Constant swirling mists cloak you in destruction. Gain resistance to all damage.</li>
          <li><strong>Titanic Fortitude:</strong> Constitution increases by +4 (max 24).</li>
          <li><strong>Eternal Wrath:</strong> Transformation has no time limit—once awakened, Avatar of Wrath remains active permanently.</li>
        </ul>

        <div className="trait">
          <div className="trait-header">
            <h3>World-Shattering Blows</h3>
          </div>
          <p>
            Each strike you land sends tremors rippling through the earth, fire splitting the ground as your rage reshapes the battlefield. The force of your fury leaves foes scrambling to keep their footing, their world crumbling beneath your relentless assault.
          </p>
          <p>
            On a critical hit, the ground erupts in flames, forcing all enemies within 20 feet to make a Strength saving throw (DC = 8 + Constitution modifier + proficiency bonus) or be knocked prone and take 4d10 fire damage.
          </p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Roleplaying the Path of Wildscar: Embrace the Chaos</h3>
        </div>
        <p>
          Followers of Wildscar are not bound by tradition or law; they are driven by instinct and passion. They embody the duality of rage, wielding it as a weapon that can either protect their allies or destroy everything in their path. As champions of chaos, they view every battle as an opportunity to revel in their primal strength. To walk the Path of Wildscar is to burn brightly, whether as a beacon for your allies or as a consuming flame that leaves nothing behind.
        </p>
      </div>

      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={wildscarImage} 
                alt="Wildscar: Ember Fury Path"
                className="modal-image" 
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={wildscarImage}
                download="wildscar_emberfury.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </StyledEmberFuryPage>
  );
};

export default WildscarEmberFury;