import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Humanity/humanity.jpg';
import './SpeciesPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesHumanity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Humanity: The Anointed Remnant" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Humanity: The Anointed Remnant</h1>
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            On Velum, Humanity stands as a testament to unity and resilience, a people forged in the crucible of a harsh and unforgiving world. Their history speaks of a singular dedication to survival and a drive to master their environment. They received a message from the cosmos, a cryptic communication that they believed was from their god, warning of an approaching leviathan. In response, they poured their resources into a dimensional net to ensnare the entity. It was in their attempt to stop the leviathan that the Shattering occurred, ripping their world and countless others apart.
          </p>
        </div>

        <div className="content-block">
          <p>
            Amidst this cataclysmic upheaval, a figure emerged from the void, his presence a symphony of power and promise. He called himself Sacrator, and he declared himself to be the god they had long worshipped without truly knowing his form. He declared them his chosen, the Anointed Remnant, and, as a symbol of his divine selection, he enacted a transformative ritual that changed them at a fundamental level.
          </p>
        </div>

        <div className="content-block">
          <p>
            He infused their blood with microscopic nanites, turning their lifeblood a vibrant, luminous blue. This was not a curse but a divine endowment, a biological bond to their technology that made them unlike any other race on the Velum. It was a mark of their status, the chosen inheritors of a destiny written by the hand of their god.
          </p>
        </div>

        <div className="content-block">
          <p>
            Under Sacrator's influence, Humanity embraced their new purpose with a zealous fervor. Their cities, rebuilt from the ruins of the old, became testaments to their ingenuity, powered by the energy of their blue blood and the strength of their resolve. Their beliefs were galvanized, and a singular ideology took hold: they were the inheritors of Velum, tasked with imposing their technologically driven, divinely sanctioned order on a world in chaos.
          </p>
        </div>

        <div className="content-block">
          <h2>Physiology</h2>
          <h3>Blue Blood</h3>
          <p>
            The most distinctive feature of Humanity is their vibrant blue blood, coursing with microscopic nanites. This grants them an innate connection to their technology and is a visible reminder of their unique heritage. The nanites are not a universal key but allow for their technology to be personalized to their bloodline.
          </p>
          <h3>Enhanced Physique</h3>
          <p>
            Their history of facing a harsh environment and Sacrator's influence have cultivated a physique that is both robust and adaptable.
          </p>
          <h3>Technological Affinity</h3>
          <p>
            They have an intuitive understanding of machinery and can often manipulate it with greater ease than others. They cannot use magic and any external magic is neutralized by their nanites.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase two ability scores of your choice by 2.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>Humans reach adulthood in their late teens and live less than a century.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>Humanity's alignment varies, though their rigid societal structure and belief in their destiny often steer them towards lawful alignments.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Humans vary widely in height and build, from barely 5 feet to well over 6 feet tall. Regardless of your position in that range, your size is Medium.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Technological Proficiency</h3>
            </div>
            <p>You have proficiency in the Technology skill. If a skill proficiency is already granted from another source, such as your background or class, you may gain expertise in Technology instead, doubling your proficiency bonus when making checks with this skill.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Augmented Reality Interface</h3>
            </div>
            <p>The nanites in your bloodstream create a constant augmented reality overlay, granting you the following benefits:</p>
            <p><strong>Wireless Communication:</strong> You can communicate telepathically with any other member of the Humanity race within 5 miles, provided they also have this racial trait. This communication is silent and instantaneous. You can also use this ability to communicate with any of their own technology within range, sending and receiving basic information.</p>
            <p><strong>Heads-Up Display (HUD):</strong> You have a constant display of information projected directly into your field of vision. This HUD can display information such as time, direction, distance to known locations or objects you have previously seen or been to, as well as tactical data, and a personal map of areas you have personally explored.</p>
            <p><strong>Digital Journal:</strong> You can create and access a digital journal, storing text, images, and even short audio/video recordings. You can record audio and video, storing up to your character level in seconds of such media per day, up to your proficiency bonus. This journal is stored within your internal nanite network and can be accessed at will. The system has nearly unlimited storage for text and images.</p>
            <p><strong>Technological Interface:</strong> You can use your mind to wirelessly connect to and interact with available compatible technology within 30 feet. The level of control depends on the complexity of the technology and may require an Intelligence (Technology) check.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Electrical Affinity</h3>
            </div>
            <p>You have resistance to lightning damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shocking Grasp</h3>
            </div>
            <p>You can channel the electrical energy within your body to deliver a powerful shock. You can use your action to make a melee attack against a creature within 5 feet. On a hit, the target takes 1d8 lightning damage, and it can't take reactions until the start of its next turn. The damage increases to 2d8 at 5th level, 3d8 at 11th level, and 4d8 at 17th level.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Lightning Burst</h3>
            </div>
            <p>Starting at 5th level, a number of times per day equal to your proficiency bonus, you can unleash a burst of electrical energy in a 5-foot-wide, 30-foot-long line as an action. Each creature in the line must make a Dexterity saving throw (DC 8 + your Constitution modifier + your proficiency bonus). A creature takes 3d6 lightning damage on a failed save, or half as much damage on a successful one. The lightning ignites flammable objects in the area that aren't being worn or carried. This damage increases to 5d6 at 11th level and 8d6 at 17th level.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Arcane Incompatibility</h3>
            </div>
            <p>Your blue blood and nanites prevent you from casting spells or using any item that requires the use of magic.</p>
            <p>If you attempt to use a magic item such as a wand or scroll, you must make an Arcana check (DC 10 + the level of the spell contained in the item). On a successful check you can use the item and the spell contained. If you fail, you are unable to use the item. Even if the check is successful, you must still meet all requirements as though you were casting the spell normally including spell slots if required.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Glimpse of the Anointed</h3>
            </div>
            <p>It is whispered that the purest among humanity, often those within the High Council or respected elders, show their pure connection to Sacrator by having eyes that perpetually glow a brilliant blue. Furthermore, magic will not affect such individuals, their very essence rejecting the arcane.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can speak, read, and write Common and one other language of your choice.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h2>Roleplaying Considerations</h2>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Devout:</strong> You are deeply committed to the teachings and will of Sacrator, and you strive to live your life in accordance with his divine plan.</p>
            <p><strong>Innovative:</strong> You have a natural curiosity and a knack for problem-solving, always seeking new ways to improve and innovate.</p>
            <p><strong>Resilient:</strong> You have a strong will and the ability to persevere through hardship, driven by your faith and the knowledge that you are part of a greater purpose.</p>
            <p><strong>Ambitious:</strong> You are driven by a desire to achieve greatness, both for yourself and for the glory of Sacrator and Humanity.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Order:</strong> You believe in the importance of structure and hierarchy, and you work to maintain and enforce the laws and traditions of your society.</p>
            <p><strong>Progress:</strong> You are dedicated to the advancement of technology and knowledge, seeing it as the key to Humanity's future and the fulfillment of Sacrator's will.</p>
            <p><strong>Faith:</strong> You have unwavering belief in Sacrator and his divine plan, and you strive to live your life in accordance with his teachings.</p>
            <p><strong>Unity:</strong> You value the strength and cohesion of your community, and you work to foster harmony and cooperation among your people.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>Divine Connection:</strong> You have a deep and personal connection to Sacrator, and you feel a strong sense of duty and loyalty to him and his will.</p>
            <p><strong>Technological Legacy:</strong> You are proud of the technological achievements of your people, and you feel a responsibility to preserve and build upon this legacy.</p>
            <p><strong>Community:</strong> You have strong ties to your family, friends, or community, and you value the support and camaraderie they provide.</p>
            <p><strong>Mission:</strong> You are committed to a specific goal or quest, given to you by Sacrator or your superiors, and you are determined to see it through to the end.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Fanatical:</strong> Your devotion to Sacrator and his will can sometimes blind you to other perspectives, leading you to act impulsively or without consideration for others.</p>
            <p><strong>Stubborn:</strong> You can be inflexible and unwilling to change your mind, even in the face of new information or changing circumstances.</p>
            <p><strong>Isolated:</strong> Your focus on your divine mission and the teachings of Sacrator can sometimes set you apart from others, making it difficult for you to form meaningful connections.</p>
            <p><strong>Overzealous:</strong> Your enthusiasm for progress and innovation can sometimes lead you to act recklessly, without fully considering the potential consequences.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Humanity: The Anointed Remnant"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Humanity.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesHumanity; 