import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import sacrathorImage from '../../assets/images/deities/Sacrathor.jpg';
import './DeityPathPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const nanoPulse = keyframes`
  0%, 100% { 
    text-shadow: 0 0 30px #0047AB, 0 0 50px #0047AB;
    box-shadow: 0 0 20px rgba(0, 71, 171, 0.3);
  }
  50% { 
    text-shadow: 0 0 50px #4169E1, 0 0 70px #4169E1;
    box-shadow: 0 0 30px rgba(65, 105, 225, 0.5);
  }
`;

const circuitFlow = keyframes`
  0% { background-position: 0% 0%; }
  100% { background-position: 200% 0%; }
`;

const nanoStream = keyframes`
  0% { transform: translateY(-100%); opacity: 0; }
  50% { opacity: 0.8; }
  100% { transform: translateY(100%); opacity: 0; }
`;

const StyledDominionPage = styled.div`
  background: #000;
  
  .deity-path-title h1 {
    font-size: 4rem;
    background: linear-gradient(45deg, #0047AB, #4169E1, #000080);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 4px;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
    
    &::before, &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 
        linear-gradient(90deg,
          transparent,
          rgba(0, 71, 171, 0.2),
          transparent
        );
      background-size: 200% 100%;
      animation: ${circuitFlow} 3s linear infinite;
      z-index: -1;
    }

    &::after {
      background: 
        repeating-linear-gradient(
          90deg,
          transparent,
          transparent 50px,
          rgba(65, 105, 225, 0.1) 50px,
          rgba(65, 105, 225, 0.1) 51px,
          transparent 51px,
          transparent 100px
        );
      animation: ${circuitFlow} 20s linear infinite;
      opacity: 0.5;
    }
  }

  .content-block, .trait {
    background: rgba(0, 0, 0, 0.9);
    border: 1px solid rgba(0, 71, 171, 0.3);
    box-shadow: 
      0 0 20px rgba(0, 71, 171, 0.1),
      inset 0 0 30px rgba(0, 71, 171, 0.05);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    margin: 2rem 0;
    padding: 2rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        linear-gradient(45deg,
          transparent 0%,
          rgba(0, 71, 171, 0.03) 45%,
          rgba(65, 105, 225, 0.05) 50%,
          rgba(0, 71, 171, 0.03) 55%,
          transparent 100%
        );
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      background: linear-gradient(to bottom, 
        rgba(0, 71, 171, 0.5),
        rgba(65, 105, 225, 0.5),
        rgba(0, 0, 128, 0.5)
      );
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      animation: ${nanoStream} 2s linear infinite;
      opacity: 0.3;
      filter: blur(1px);
    }

    &:hover {
      border-color: rgba(0, 71, 171, 0.6);
      box-shadow: 
        0 0 30px rgba(0, 71, 171, 0.2),
        inset 0 0 50px rgba(0, 71, 171, 0.1);
      transform: translateY(-2px);

      &::after {
        opacity: 0.6;
      }
    }
  }

  h2 {
    color: #0047AB;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(0, 71, 171, 0.5);
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, transparent, #0047AB, transparent);
      animation: ${nanoPulse} 2s infinite;
    }
  }

  h3 {
    color: #4169E1;
    font-size: 1.5rem;
    text-shadow: 0 0 8px rgba(65, 105, 225, 0.4);
    margin: 1rem 0;
    transition: all 0.3s ease;
    
    &:hover {
      color: #0047AB;
      text-shadow: 0 0 12px rgba(0, 71, 171, 0.6);
    }
  }

  p {
    color: #E5E4E2;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(229, 228, 226, 0.5);
  }

  strong {
    color: #4169E1;
    text-shadow: 0 0 5px rgba(65, 105, 225, 0.5);
    font-weight: bold;
    position: relative;
    padding: 0 3px;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 71, 171, 0.1);
      transform: skew(-15deg);
      z-index: -1;
    }
  }

  ul li {
    color: #E5E4E2;
    position: relative;
    
    &::before {
      color: #4169E1 !important;
      text-shadow: 0 0 5px rgba(65, 105, 225, 0.5);
    }
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #0047AB, #4169E1);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 0 0 10px rgba(0, 71, 171, 0.5);
    }
  }

  .veiled-paths h2 {
    color: #0047AB;
    text-shadow: 0 0 10px rgba(0, 71, 171, 0.5);
    
    &::after {
      display: none;
    }
  }

  .veiled-link {
    color: #E5E4E2 !important;
    transition: all 0.3s ease;

    &:hover {
      color: #4169E1 !important;
      text-shadow: 0 0 8px rgba(65, 105, 225, 0.4);
    }
  }

  .image-modal {
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(10px);

    .modal-image {
      box-shadow: 0 0 30px rgba(0, 71, 171, 0.2);
      border: 1px solid rgba(65, 105, 225, 0.3);
    }

    .modal-close,
    .modal-download {
      background: rgba(0, 0, 0, 0.8);
      border: 1px solid rgba(0, 71, 171, 0.3);
      color: #4169E1;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(0, 71, 171, 0.1);
        border-color: #4169E1;
      }
    }
  }

  .deity-path-background {
    filter: brightness(1.1) contrast(1.1) saturate(1.2);
    transition: filter 0.3s ease;

    &:hover {
      filter: brightness(1.2) contrast(1.2) saturate(1.3);
    }
  }

  .blue-text {
    color: #0047AB !important;
    text-shadow: 0 0 10px rgba(0, 71, 171, 0.5) !important;
  }
`;

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 }
};

const contentVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.7, ease: "easeOut" }
};

const traitVariants = {
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5, ease: "easeOut" }
};

const SacrathorDominion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullImage, setShowFullImage] = useState(false);

  // The sequence of pages for navigation - following dropdown menu order
  const pageSequence = [
    '/deities/caelumbris/shadowed',
    '/deities/firstborn/soulbound',
    '/deities/libris/knowledge',
    '/deities/nebula/mistwalker',
    '/deities/queenmab/frozenveil',
    '/deities/queen-titania/wildgrowth',
    '/deities/sacrathor/dominion',
    '/deities/unbound',
    '/deities/valdis/justice',
    '/deities/wildscar/emberfury'
  ];

  return (
    <StyledDominionPage className="deity-path-page sacrathor-page">
      <div className="deity-path-hero">
        <img 
          src={sacrathorImage} 
          alt="Sacrathor: Dominion Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Sacrathor: The Path of Dominion</h1>
        </div>
      </div>

      <div className="content-block requirements-section">
        <div className="edicts">
          <h2>Edicts</h2>
          <ul>
            <li>Establish Humanity's dominance over all other races, guiding them to assert their rightful place as the unchallenged rulers of the Shattered Realm.</li>
            <li>Embrace and utilize technology as a tool of domination, wielding its power to forge a new era of prosperity and order under Humanity's unyielding rule.</li>
            <li>Inspire unwavering loyalty and obedience among Humanity's factions, uniting them under a single banner to achieve their shared destiny of absolute dominion.</li>
          </ul>
        </div>
        
        <div className="requirements">
          <h2>Requirements</h2>
          <ul>
            <li>Race: Humanity</li>
          </ul>
        </div>
      </div>

      <div className="content-block dark-block">
        <h2 className="blue-text">Walking the Path of Dominion</h2>
        <p>
          To walk the Path of Dominion is to claim humanity's rightful place as the pinnacle of creation. Through a fusion of advanced technology and commanding charisma, you reshape the world in your image. Sacrathor's chosen stand as the vanguard of human evolution, radiating authority that inspires devotion and demands submission. With nanite miracles and sheer force of will, they forge a new era where humanity reigns supreme.
        </p>
        <p>
          This path demands unwavering self-belief and an unshakable conviction in humanity's potential for greatness. More than mere leaders, you are icons of progress, wielding innovation and authority to bend the world to your vision.
        </p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Sacrathor: The Architect of Perfection</h3>
        </div>
        <p>
          Sacrathor, the god of divine authority and human perfection, exemplifies the seamless union of advanced technology and commanding charisma. His domain is a gleaming metropolis where every surface radiates his influence, and every innovation showcases his unmatched brilliance. To his followers, Sacrathor represents the pinnacle of human achievement – a flawless synthesis of brilliance, charm, and technological advancement.
        </p>
        <p>
          His presence is both awe-inspiring and daunting. Where Sacrathor treads, the air hums with the power of nanites, and his every word carries the weight of undeniable truth. His followers strive to emulate his perfection, reshaping themselves and the world in his image, becoming instruments of a new age where humanity transcends all limitations and compels all others to kneel.
        </p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Sacrathor's Domain: The Pinnacle of Civilization</h3>
        </div>
        <p>
          Sacrathor's domain is a dazzling metropolis of technological marvels. Towers of glass and steel ascend to impossible heights, their surfaces alive with glowing nanite circuitry that pulses like the lifeblood of the city. The streets below hum with energy, a seamless blend of human ingenuity and divine innovation, where every detail speaks to the perfection of Sacrathor's vision. Every facet of this domain reflects Sacrathor's genius, from the automated systems that ensure flawless operation to the omnipresent holographic displays that broadcast his image.
        </p>
        <p>
          The very air is alive with nanotechnology, a constant reminder of Sacrathor's vigilance. Perfection is the unwavering standard, and any deviation from the established order is swiftly rectified. Within this domain, Sacrathor's chosen are exalted as paragons of humanity, their every action a testament to their divine purpose.
        </p>
      </div>

      <h2>Abilities</h2>
      <div className="trait">
        <div className="trait-header">
          <h3>Level 3: Ascendant's Command</h3>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Nanite Presence</h3>
          </div>
          <p>Swarms of microscopic nanites embedded in your body perform subtle but transformative functions, enhancing your presence and reinforcing your superiority.</p>
          <ul className="specializations-list">
            <li>Your appearance is always immaculate, with attire and grooming maintained to perfection.</li>
            <li>Your form radiates an aura of unshakable authority, adorned with glowing insignias and regal enhancements.</li>
            <li>Minor effects mimic Prestidigitation, allowing you to clean, heat, cool, or create sensory enhancements at will.</li>
            <li>Effect: Gain a +1 bonus to Charisma as a manifestation of your enhanced presence.</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Deceptive Radiance</h3>
          </div>
          <p>Once per long rest, you can project an overwhelming aura of divine light within a 30-foot radius for 1 minute.</p>
          <ul className="specializations-list">
            <li>Creatures within the radius must succeed on a Wisdom saving throw (DC = 8 + your Charisma modifier + your proficiency bonus) or become charmed for the duration.</li>
            <li>Charmed creatures view you as a divinely sanctioned leader and are compelled to follow your commands, provided they do not cause direct harm to themselves.</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Enforced Obedience</h3>
          </div>
          <p>As a reaction, your nanites swarm over a creature that defies your will, creating a visible shimmer of divine technology across their form.</p>
          <ul className="specializations-list">
            <li>The creature must succeed on a Wisdom saving throw or be compelled to obey your command for 1 hour per level.</li>
            <li>This effect enforces absolute obedience and creates a sensory impression of your authority.</li>
            <li>Usable a number of times equal to your proficiency bonus per long rest.</li>
          </ul>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Level 5: Architect's Influence</h3>
        </div>
        
        <div className="trait">
          <div className="trait-header">
            <h3>Nanite Augmentation</h3>
          </div>
          <p>Your body undergoes further refinement through Sacrathor's divine technology, enhancing both strength and speed.</p>
          <ul className="specializations-list">
            <li>Increase your Strength and Dexterity by +1 (this can exceed normal racial limits).</li>
            <li>Your reflexes sharpen, granting you advantage on Dexterity saving throws.</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Adaptive Nanite Shielding</h3>
          </div>
          <p>Your nanites dynamically reinforce your body's structure, protecting you from harm.</p>
          <ul className="specializations-list">
            <li>You gain resistance to lightning and thunder damage.</li>
            <li>As a reaction, when you take damage from a non-magical source, you can harden your nanites, reducing the damage taken by an amount equal to your level. You can use this feature a number of times equal to your proficiency bonus per long rest.</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Hyper-Efficiency</h3>
          </div>
          <p>Sacrathor's technology optimizes your body beyond mortal limits.</p>
          <ul className="specializations-list">
            <li>Your endurance is vastly improved, allowing you to ignore exhaustion effects up to level 3.</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Electrowhip Conduit</h3>
          </div>
          <p>Sacrathor grants you mastery over a specialized technological weapon—an electrically charged whip designed for both offense and battlefield control.</p>
          <ul className="specializations-list">
            <li>As a bonus action, you can summon an Electrowhip, a weapon composed of arcing electricity and metallic tendrils.</li>
            <li>The whip has a reach of 15 feet and deals 1d8 lightning damage plus 1d8 thunder damage on a hit.</li>
            <li>When you hit a creature, they must make a Strength saving throw (DC = 8 + proficiency + Strength or Dexterity modifier) or become restrained until the end of your next turn.</li>
            <li>You can use this ability a number of times equal to your proficiency bonus per long rest.</li>
            <li>If disarmed or dismissed, the whip dissipates into harmless energy and can be resummoned using a bonus action.</li>
          </ul>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Overcharged Strike</h3>
        </div>
        <p>You gain access to a powerful attack that channels pure energy through your body and weapon.</p>
        <ul className="specializations-list">
          <li>Once per turn, when you hit with a melee or ranged attack, you may deal an additional 2d10 lightning damage.</li>
          <li>You may use this ability a number of times equal to your proficiency bonus per long rest.</li>
          <li>Your reflexes are enhanced, granting you advantage on Initiative rolls.</li>
        </ul>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Level 10: Unbreakable Dominion</h3>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Nanite Overclocking</h3>
          </div>
          <p>Your neural pathways and body structure undergo radical optimization through divine technology, allowing for near-instantaneous adaptation and decision-making.</p>
          <ul className="specializations-list">
            <li>Gain +1 Intelligence and Wisdom (this can exceed normal racial limits).</li>
            <li>Your mental processing speed allows you to take two reactions per round, rather than one.</li>
            <li>You gain advantage on Intelligence checks and saving throws.</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Kinetic Overdrive</h3>
          </div>
          <p>Your movements are refined to near-perfection, allowing you to manipulate energy and momentum with technological precision.</p>
          <ul className="specializations-list">
            <li>Your movement speed increases by 15 feet.</li>
            <li>Your melee and ranged attacks ignore resistance to lightning damage.</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Overcharged Strike</h3>
          </div>
          <ul className="specializations-list">
            <li>Once per turn, when you hit with a melee or ranged attack, you may deal an additional 2d10 lightning damage.</li>
            <li>You may use this ability a number of times equal to your proficiency bonus per long rest.</li>
            <li>Your reflexes are enhanced, granting you advantage on Initiative rolls.</li>
          </ul>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Level 15: Pinnacle Form</h3>
        </div>
        <p>At this level, Sacrathor's chosen achieve their ultimate form, embodying the perfection of humanity fused with divine technology.</p>

        <div className="trait">
          <div className="trait-header">
            <h3>Pinnacle Transformation</h3>
          </div>
          <p>Your body radiates golden-white light, and your metallic wings unfurl, gleaming with divine energy.</p>
          <ul className="specializations-list">
            <li>You can change your size as a bonus action, shifting between Small, Medium, or Large at will.</li>
            <li>You gain a flying speed of 30 feet and the ability to hover effortlessly.</li>
            <li>Your AC increases by +2, and after completing a long rest, you gain temporary hit points equal to your level.</li>
            <li>Your melee and ranged attacks deal additional radiant damage equal to your Charisma modifier.</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Divine Wrath</h3>
          </div>
          <p>You may release a burst of unparalleled divine power when you hit a creature with an attack:</p>
          <ul className="specializations-list">
            <li>All enemies within 60 feet must make a Charisma saving throw (DC = 8 + proficiency + Charisma modifier).</li>
            <li>On a failure: They take 10d12 radiant damage and are blinded for 1 minute. Additionally, they are sheathed in a glowing light, granting advantage on the next attack made against them.</li>
            <li>On a success: They take half damage, are not blinded, but are sheathed in a glowing light, granting advantage on the next attack against them.</li>
            <li>You may use this ability a number of times equal to your proficiency bonus per long rest.</li>
          </ul>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Roleplaying the Path of Sacrathor: The Chosen Architects</h3>
        </div>
        <p>
          Followers of Sacrathor are icons of human excellence and technological progress. They move through the world with an air of absolute confidence, embodying innovation and unrelenting ambition. Each interaction reinforces their dominion, whether through their commanding presence or the seamless integration of technology into their very being. Their presence is magnetic, their authority unshakable, and their every action a testament to the belief that humanity is destined to dominate.
        </p>
        <p>
          Choosing this path is a declaration of supremacy. Practitioners of the Path of Sacrathor do not request obedience; they command it. They do not negotiate; they dictate. With nanotechnology enhancing their every step and charisma that reshapes the perceptions of all who meet their gaze, they are the architects of a new world order.
        </p>
      </div>

      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={sacrathorImage} 
              alt="Sacrathor: Dominion Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={sacrathorImage}
              download="Sacrathor.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </StyledDominionPage>
  );
};

export default SacrathorDominion;