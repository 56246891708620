import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import nebulaImage from '../../assets/images/deities/redmist.jpg';
import './DeityPathPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const mistFlow = keyframes`
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
`;

const magicPulse = keyframes`
  0%, 100% { 
    text-shadow: 0 0 20px rgba(220, 20, 60, 0.8),
                 0 0 40px rgba(220, 20, 60, 0.4),
                 0 0 60px rgba(220, 20, 60, 0.2);
  }
  50% { 
    text-shadow: 0 0 30px rgba(220, 20, 60, 0.9),
                 0 0 50px rgba(220, 20, 60, 0.5),
                 0 0 70px rgba(220, 20, 60, 0.3);
  }
`;

const moonGlow = keyframes`
  0%, 100% { filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.8)); }
  50% { filter: drop-shadow(0 0 25px rgba(255, 255, 255, 0.4)); }
`;

const StyledMistPage = styled.div`
  background: #0a0a0a;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      linear-gradient(
        135deg,
        rgba(10, 10, 10, 0.97),
        rgba(40, 0, 0, 0.95)
      ),
      radial-gradient(
        circle at center,
        rgba(220, 20, 60, 0.1),
        rgba(0, 0, 0, 0.98)
      );
    background-size: 400% 400%;
    animation: ${mistFlow} 15s ease infinite;
    z-index: -1;
  }

  .deity-path-title h1 {
    font-size: 4rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
    
    background: linear-gradient(45deg, #DC143C, #FF69B4, #DC143C);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 4px;
    animation: ${magicPulse} 3s ease-in-out infinite;
    position: relative;
    
    &::after {
      content: '☽';
      position: absolute;
      top: -0.5em;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.5em;
      color: #fff;
      animation: ${moonGlow} 2s ease-in-out infinite;
    }
  }

  .content-block, .trait {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(220, 20, 60, 0.3);
    box-shadow: 
      0 0 20px rgba(220, 20, 60, 0.1),
      inset 0 0 30px rgba(220, 20, 60, 0.05);
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;
    margin: 2rem 0;
    padding: 2rem;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        linear-gradient(45deg,
          transparent,
          rgba(220, 20, 60, 0.05),
          transparent
        );
      background-size: 200% 200%;
      animation: ${mistFlow} 3s linear infinite;
      pointer-events: none;
    }

    &:hover {
      border-color: rgba(220, 20, 60, 0.6);
      box-shadow: 
        0 0 30px rgba(220, 20, 60, 0.2),
        inset 0 0 50px rgba(220, 20, 60, 0.1);
      transform: translateY(-2px);
    }
  }

  h2 {
    color: #DC143C;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(220, 20, 60, 0.5);
    margin-bottom: 1rem;
  }

  h3 {
    color: #FF69B4;
    font-size: 1.5rem;
    text-shadow: 0 0 8px rgba(255, 105, 180, 0.4);
    margin: 1rem 0;
  }

  p {
    color: #E0E0E0;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
  }

  strong {
    color: #FF69B4;
    text-shadow: 0 0 5px rgba(255, 105, 180, 0.3);
    font-weight: bold;
  }

  ul li {
    color: #E0E0E0;
    position: relative;
    
    &::before {
      content: '✧';
      color: #DC143C;
      position: absolute;
      left: -1.5rem;
      text-shadow: 0 0 5px rgba(220, 20, 60, 0.5);
    }
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #DC143C, #FF69B4);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 0 0 10px rgba(220, 20, 60, 0.5);
    }
  }

  .requirements-section {
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(220, 20, 60, 0.4);
    box-shadow: 0 0 15px rgba(220, 20, 60, 0.1);
  }

  .veiled-paths h2 {
    color: #DC143C;
    text-shadow: 0 0 10px rgba(220, 20, 60, 0.5);
  }

  .veiled-link {
    color: #E0E0E0;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: #FF69B4;
      text-shadow: 0 0 8px rgba(255, 105, 180, 0.4);
    }
  }

  .specializations-list {
    list-style: none;
    padding-left: 1.5rem;

    li {
      position: relative;
      margin-bottom: 1rem;

      strong {
        color: #FF69B4;
        text-shadow: 0 0 5px rgba(255, 105, 180, 0.3);
      }

      &::before {
        content: "✦";
        color: #DC143C;
        position: absolute;
        left: -1.5rem;
        opacity: 0.8;
      }

      &:hover {
        transform: translateX(5px);
        
        &::before {
          opacity: 1;
          text-shadow: 0 0 5px rgba(220, 20, 60, 0.5);
        }
      }
    }
  }

  .image-modal {
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(10px);

    .modal-image {
      box-shadow: 0 0 30px rgba(220, 20, 60, 0.2);
    }

    .modal-close,
    .modal-download {
      background: rgba(0, 0, 0, 0.8);
      border: 1px solid rgba(220, 20, 60, 0.3);
      color: #DC143C;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(220, 20, 60, 0.1);
        border-color: #DC143C;
      }
    }
  }

  .deity-path-background {
    filter: brightness(0.9) contrast(1.1) saturate(1.2);
    transition: filter 0.3s ease;

    &:hover {
      filter: brightness(1) contrast(1.2) saturate(1.3);
    }
  }
`;

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 }
};

const contentVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.7, ease: "easeOut" }
};

const traitVariants = {
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5, ease: "easeOut" }
};

const NebulaMistWalker = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullImage, setShowFullImage] = useState(false);

  // The sequence of pages for navigation - following dropdown menu order
  const pageSequence = [
    '/deities/caelumbris/shadowed',
    '/deities/firstborn/soulbound',
    '/deities/libris/knowledge',
    '/deities/nebula/mistwalker',
    '/deities/queenmab/frozenveil',
    '/deities/queen-titania/wildgrowth',
    '/deities/sacrathor/dominion',
    '/deities/unbound',
    '/deities/valdis/justice',
    '/deities/wildscar/emberfury'
  ];

  return (
    <StyledMistPage className="deity-path-page">
      <motion.div 
        className="deity-path-page"
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <motion.div 
          className="deity-path-hero"
          variants={contentVariants}
        >
          <img 
            src={nebulaImage} 
            alt="Nebula: Mistwalker Path" 
            className="deity-path-background"
            onClick={() => setShowFullImage(true)}
            style={{ cursor: 'pointer' }}
          />
          <div className="deity-path-title">
            <h1>Nebula: The Mistwalker</h1>
          </div>
        </motion.div>

        <motion.div 
          className="content-block requirements-section"
          variants={contentVariants}
        >
          <div className="edicts">
            <h2>Edicts</h2>
            <p>Spread the use of magic within Velum.</p>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <p>The ability to cast a 1st-level spell, whether through a spellcasting class or a racial trait.</p>
          </div>
        </motion.div>

        <motion.div 
          className="content-block dark-block"
          variants={contentVariants}
        >
          <h2>Walking the Path of the Mistwalker</h2>
          <p>
            Those who follow the Mistwalker path embrace the ethereal presence of Nebula, the Veiled One. They become conduits for the ambient magic that permeates Velum, channeling its power to enhance their spell crafting abilities and weaving the mists into their very being. Mistwalkers are masters of subtle influence, their magic as elusive and pervasive as the mists themselves.
          </p>
        </motion.div>

        <h2>Mistwalker Abilities</h2>
        
        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Level 3: Veil of the Mist</h3>
          </div>
          <p>
            Each morning, as the mists gather around you, you and a number of creatures up to your proficiency bonus may choose one 1st-level spell and two cantrips from the Wizard, Sorcerer, Cleric, Druid, or Warlock spell list. The chosen cantrips and spell must be from the same list. These spells do not count against their known spells and remain available until the next dawn. If a creature granted this ability is not a spellcaster, they use Charisma as their spellcasting modifier and can cast the chosen 1st-level spell once per long rest.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Level 5: Mist-Weaving</h3>
          </div>
          <p>
            Your connection to the Veil deepens, allowing you to shape the mists with greater mastery. You can use the following mist-based effects a number of times per day equal to your proficiency bonus. These effects now last for 10 minutes unless otherwise stated:
          </p>
          <ul className="specializations-list">
            <li><strong>Warding Fog:</strong> The mists swirl around you or an ally within 30 feet, creating a shifting barrier. The target has advantage on all saving throws against spells and resistance to force and psychic damage. The fog clings to their form, flickering in and out of visibility.</li>
            <li><strong>Miststep:</strong> The veil between worlds thins, allowing you to slip through unseen pathways. You vanish into the mists and reappear up to 30 feet away in an unoccupied space. If used as a reaction to an attack, you take only half damage from the triggering attack before teleporting, leaving a ghostly mist image behind.</li>
            <li><strong>Enveloping Mists:</strong> A swirling vortex of fog surges forward, consuming a 20-foot radius centered on a point within 60 feet. The area becomes heavily obscured, blocking vision entirely. The mist seeps into the bodies of those caught within, forcing them to make a Constitution saving throw (DC = 8 + your proficiency bonus + your spellcasting ability modifier) or have their movement speed halved until the end of their next turn. Ranged attacks through the mist are at disadvantage, and you and your allies gain advantage on Stealth checks while within.</li>
            <li><strong>Nebula's Grasp:</strong> Ethereal tendrils of mist stretch out from your fingertips, slithering toward a creature within 60 feet. The target must succeed on a Strength saving throw or find themselves bound by the thick vapor, restrained until the end of your next turn. The mist coils unnaturally, ignoring magical resistance and clinging to even incorporeal beings.</li>
            <li><strong>Veiled Passage:</strong> The mists embrace you and up to three creatures within 10 feet, shrouding your forms in shifting vapor. You all become invisible for up to 1 minute. The effect ends early if a target attacks or casts a spell, leaving a faint misty echo where they once stood.</li>
            <li><strong>Storm Veil:</strong> You call upon the fury of the storm, summoning electrified mist that crackles with arcane energy. The mist arcs between up to three creatures within 30 feet, dealing 2d8 lightning damage. Each target must make a Constitution saving throw or be momentarily overwhelmed, becoming stunned until the start of your next turn as static energy courses through them.</li>
            <li><strong>Void Shroud:</strong> A pulse of abyssal mist surges outward in a 15-foot cone, its eerie chill biting into flesh and bone. All creatures within take 2d10 cold damage, and any magical effects granting resistance to cold are suppressed for 1 minute. The mist clings to their forms like frozen tendrils, slowing their movements.</li>
            <li><strong>Veil of Slumber:</strong> The mist whispers lullabies of forgotten dreams, swirling around creatures in a 20-foot radius within 60 feet. Each creature in the area must succeed on a Wisdom saving throw or be lulled into a deep, dreamless sleep for 1 minute or until they take damage.</li>
            <li><strong>Delirium Fog:</strong> The mists warp perception, distorting reality in a shifting cloud of confusion. You summon a mind-clouding mist in a 20-foot cube within 60 feet. Any creature in the area must succeed on an Intelligence saving throw or become confused as per the confusion spell for 1 minute, stumbling through the mist as their senses betray them.</li>
          </ul>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Level 10: Mist-Transformation</h3>
          </div>
          <p>At this stage, the Mistwalker has become a true conduit of Nebula's magic, seamlessly blending into the arcane mist.</p>
          <ul className="specializations-list">
            <li><strong>Elemental Transmutation:</strong> When casting a spell that deals damage, you can change its damage type to fire, cold, lightning, acid, or thunder. This applies to all spells you cast, overriding their default element.</li>
            <li><strong>Spellward Presence:</strong> The veil protects you from the worst of magic's effects. You have advantage on all saving throws against spells and magical effects.</li>
            <li><strong>Arcane Sight:</strong> Your eyes glow with an unnatural shimmer, revealing the secrets of magic itself. You have Detect Magic permanently active, requiring no concentration. You can suppress or reactivate this ability at will.</li>
            <li><strong>Veilborne Flight:</strong> The mists carry you effortlessly, making you one with the shifting currents of magic. You gain a flying speed equal to your walking speed, leaving behind a faint, shimmering mist.</li>
            <li><strong>Shifting Form:</strong> As an action, you can Polymorph yourself into another creature using the spell Polymorph. You can do this a number of times per long rest equal to your proficiency bonus.</li>
          </ul>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Level 15: Ascendant of the Mists</h3>
          </div>
          <p>At this level, you transcend the boundaries of mortal spellcasting, becoming a living embodiment of the arcane mist.</p>

          <div className="trait">
            <div className="trait-header">
              <h3>Form of Arcane Essence</h3>
            </div>
            <p>You permanently become a being of pure magic. Your body transforms into an incorporeal mist-like form, granting you the following:</p>
            <ul className="specializations-list">
              <li>You are resistant to non-magical weapon damage.</li>
              <li>You can move through objects and creatures as if they were difficult terrain.</li>
              <li>You no longer require verbal, somatic, or material components for spells unless they have a gold cost.</li>
              <li>You radiate an aura (30 feet) that allows any spell cast within it to be cast without requiring concentration (only one spell per turn can benefit from this effect).</li>
            </ul>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Arcane Convergence</h3>
            </div>
            <p>Whenever you cast a spell of 3rd level or lower, you can have it replicate a second time within 30 feet, affecting a second valid target.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Living Spellfont</h3>
            </div>
            <ul className="specializations-list">
              <li>Non-magic users within 30 feet of you can choose one spell from a set list and cast it as a cantrip. The available spells are: Prestidigitation, Mage Hand (Wizard), Minor Illusion, Shocking Grasp (Sorcerer), Sacred Flame, Thaumaturgy (Cleric), Druidcraft, Produce Flame (Druid), Eldritch Blast, Chill Touch (Warlock).</li>
              <li>Any spell cast within 30 feet of you has its range doubled, and spells requiring attack rolls do not suffer disadvantage from half-cover.</li>
            </ul>
          </div>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Embracing the Mistwalker Path</h3>
          </div>
          <p>
            Mistwalkers are often solitary figures, their magic reflecting their connection to the elusive mists. 
            They are drawn to places where the mists are thickest, seeking to deepen their connection to Nebula's power. 
            Some Mistwalkers become hermits, living in secluded areas where the mists are ever-present. Others wander 
            the land, their magic a subtle force that shapes the world around them.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Roleplaying a Mistwalker</h3>
          </div>
          <p>
            Mistwalkers are often enigmatic figures, their motives as elusive as the mists they command. They are 
            drawn to the mysteries of the world, seeking to understand the subtle forces that shape reality. Some 
            Mistwalkers are driven by a desire to protect the balance of magic, while others seek to harness its 
            power for their own ends.
          </p>
        </motion.div>

        {showFullImage && (
          <div className="image-modal" onClick={() => setShowFullImage(false)}>
            <div className="modal-image-container">
              <div className="modal-content">
                <img 
                  src={nebulaImage} 
                  alt="Nebula, The Mistwalker"
                  className="modal-image" 
                />
                <button 
                  className="modal-close"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFullImage(false);
                  }}
                >
                  ✕
                </button>
                <a 
                  href={nebulaImage}
                  download="nebula_mistwalker.jpg"
                  className="modal-download"
                  onClick={(e) => e.stopPropagation()}
                >
                  ⤓
                </a>
              </div>
            </div>
          </div>
        )}
      </motion.div>
    </StyledMistPage>
  );
};

export default NebulaMistWalker;
