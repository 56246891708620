import React, { useState } from 'react';
import pathOfWrathImage from '../../assets/images/subclasses/Wrath/pathofwrath.png';
import './ClassesPage.css';
import { useNavigate, useLocation } from 'react-router-dom';

const ClassesPathOfWrath = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="pantheon-page">
      <div className="pantheon-hero">
        <img 
          src={pathOfWrathImage} 
          alt="Path of Wrath" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-details">
          <h1>Path of Wrath</h1>
        </div>
      </div>

      <div className="content-block">
        <h2>Overview</h2>
        <p>
          The Path of Wrath calls to those who embrace fury not as a fleeting emotion but as an indomitable force that shapes their existence. These barbarians are vessels for chaos, avatars of destruction whose very presence stirs the air with anticipation of violence. Whether forged by the relentless trials of war or imbued with the essence of Wildscar, the capricious god of battle, their rage transcends mortal understanding. It is a living storm, a force of nature bound only by their will.
        </p>
        <p>
          Path of Wrath barbarians are not mere brutes; they are engines of destruction with a purpose. Their fury consumes all hesitation, turning them into unstoppable juggernauts. In battle, their wrath manifests as a tangible force, reshaping their very forms and enveloping the battlefield in an aura of primal power.
        </p>
      </div>

      <div className="content-block">
        <h2>Path Features</h2>
        <div className="trait">
          <div className="trait-header">
            <h3>Bonus Proficiencies</h3>
            <span className="level-tag">Level 3rd</span>
          </div>
          <p>When you choose this path at 3rd level, you gain proficiency in Athletics and Intimidation.</p>
          <p>You can rage a number of times per day equal to your proficiency bonus.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Fury of Wrath</h3>
            <span className="level-tag">Level 3rd</span>
          </div>
          <p>Starting at 3rd level, your rage transforms you into a force of nature. To fully tap into the raw power of your rage, you must not be encumbered by heavy or medium armor. You gain the following benefits only while wearing light armor or no armor:</p>
          
          <h4>Explosive Growth</h4>
          <p>You temporarily grow in size, enhancing your battlefield presence and raw physical strength:</p>
          <p><strong>3rd Level:</strong> Medium size if smaller, +2 Strength bonus</p>
          <p><strong>6th Level:</strong> Large size, +4 Strength bonus</p>
          <p><strong>10th Level:</strong> Huge size, +6 Strength bonus</p>

          <h4>Bloodfire Mist</h4>
          <p>While raging, you exude a swirling aura of blood-red mist that expands as you level:</p>
          <p><strong>3rd Level:</strong> 5-foot radius</p>
          <p><strong>6th Level:</strong> 10-foot radius</p>
          <p><strong>10th Level:</strong> 15-foot radius</p>
          <p><strong>20th Level:</strong> 30-foot radius</p>
          
          <p>Within this aura, you and your allies gain resistance to bludgeoning, piercing, and slashing damage. You also gain resistance to one damage type of your choice:</p>
          <p><strong>3rd Level:</strong> Acid, cold, fire, lightning, or thunder</p>
          <p><strong>6th Level:</strong> Necrotic, poison, or radiant</p>
          <p><strong>10th Level:</strong> Force, psychic, or magical</p>
          
          <p>However, creatures within the aura suffer a –1 penalty to AC, saving throws, and make all concentration checks at disadvantage. At 20th level, the penalty increases to –2.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Unyielding Will</h3>
            <span className="level-tag">Level 6th</span>
          </div>
          <p>Your indomitable spirit grants you increasing resistance to mental effects:</p>
          <p><strong>6th Level:</strong> Immunity to charmed and frightened conditions</p>
          <p><strong>10th Level:</strong> Immunity to stunned condition</p>
          <p><strong>14th Level:</strong> Resistance to psychic damage</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Wrath Unleashed</h3>
            <span className="level-tag">Level 6th</span>
          </div>
          <p>
            When you hit a creature with a melee attack, you can unleash a burst of destructive energy. Each creature within 10 feet must make a Constitution saving throw (DC 8 + proficiency bonus + Constitution modifier). Failed saves take 3d8 mixed damage (half fire, half physical) and 1d6 fire damage at the start of their next turn. Successful saves take half damage. Damage increases to 4d8 at 11th level and 5d8 at 16th level. You can use this feature a number of times equal to your proficiency bonus per long rest.
          </p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Embodiment of Wrath</h3>
            <span className="level-tag">Level 10th</span>
          </div>
          <p>The essence of your rage flows through you even outside of battle:</p>
          <p><strong>Strength Mastery:</strong> Advantage on Strength checks and saving throws</p>
          <p><strong>Enhanced Strikes:</strong> +2 bonus to melee weapon damage using Strength (+3 at 14th level, +4 at 17th level)</p>
          <p><strong>Natural Resistance:</strong> Resistance to bludgeoning, piercing, and slashing damage</p>
          <p><strong>Vitality:</strong> Gain temporary hit points equal to Constitution modifier at start of each turn</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Wrathful Incarnation</h3>
            <span className="level-tag">Level 14th</span>
          </div>
          <p>As a bonus action, become an avatar of pure wrath for 1 minute, gaining:</p>
          <p><strong>Massive Form:</strong> Grow to Huge size with Explosive Growth benefits</p>
          <p><strong>Burning Strikes:</strong> Additional 2d6 fire damage on attacks</p>
          <p><strong>Physical Mastery:</strong> Advantage on all Strength checks and saves</p>
          <p><strong>Condition Immunity:</strong> Immune to all conditions except exhaustion, paralysis, and petrification</p>
          <p><strong>Regeneration:</strong> Regain 5 hit points at start of turn if not at 0 HP</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Avatar of Wildscar</h3>
            <span className="level-tag">Level 20th</span>
          </div>
          <p>At level 20, you become a true avatar of the god of battle:</p>
          <p><strong>Unstoppable Fury:</strong> Once per day while raging, cannot be reduced below 1 HP for 1 minute</p>
          <p><strong>Wrathful Embodiment:</strong> Bloodfire Mist expands to 30-foot radius with -2 penalty</p>
          <p><strong>Limitless Rage:</strong> Always considered raging; must concentrate for 1 minute to end rage</p>
        </div>
      </div>

      <div className="content-block">
        <h2>Role Playing</h2>
        <p>
          Embodying the Path of Wrath means surrendering to your most primal instincts. You are a being of pure, unleashed fury—a living testament to destruction that inspires terror in enemies and unease in allies. Your presence is felt before you are seen, an atmosphere of barely contained violence that precedes your arrival.
        </p>

        <div className="trait">
          <div className="trait-header">
            <h3>Personality Traits</h3>
          </div>
          <p><strong>Ruthless:</strong> You see mercy as weakness and strive to dominate every situation.</p>
          <p><strong>Savage:</strong> Your instincts drive your actions, often leading to brutal solutions.</p>
          <p><strong>Unyielding:</strong> You refuse to compromise or back down, no matter the cost.</p>
          <p><strong>Instinctive:</strong> You act on gut feelings rather than reason, trusting your primal nature.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Ideals</h3>
          </div>
          <p><strong>Chaos:</strong> Destruction is the natural order; through chaos, the world is reshaped.</p>
          <p><strong>Power:</strong> Strength is the ultimate truth, and you seek to prove your might.</p>
          <p><strong>Domination:</strong> The strong must rule the weak; it is the way of Wildscar.</p>
          <p><strong>Revenge:</strong> Your fury is a tool to settle scores and destroy those who wronged you.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Bonds</h3>
          </div>
          <p><strong>Wildscar's Call:</strong> You feel bound to Wildscar's chaotic essence.</p>
          <p><strong>Scars of Wrath:</strong> A battle, loss, or betrayal fuels your rage.</p>
          <p><strong>Symbol of Fury:</strong> You wield an artifact that represents your bond to Wildscar.</p>
          <p><strong>Tribal Bond:</strong> Your connection to a savage tribe shapes your loyalty.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Flaws</h3>
          </div>
          <p><strong>Bloodthirsty:</strong> You struggle to restrain yourself from seeking violence.</p>
          <p><strong>Destructive:</strong> Your inability to hold back leaves ruin in your wake.</p>
          <p><strong>Uncontrollable:</strong> Your rage blinds you to consequences.</p>
          <p><strong>Cruel:</strong> You enjoy the suffering of those weaker than you.</p>
        </div>
      </div>

      <div className="content-block">
        <h2>Equipment</h2>
        <p>As a Path of Wrath barbarian, your equipment reflects your savage nature:</p>
        <ul>
          <li>A massive weapon adorned with symbols of rage and destruction</li>
          <li>Light armor that allows freedom of movement</li>
          <li>A totem representing your connection to Wildscar</li>
          <li>A collection of war trophies from fallen enemies</li>
          <li>Basic survival gear for a life of constant battle</li>
        </ul>
      </div>

      <div className="content-block">
        <h2>Multiclassing</h2>
        <p>To enhance your capabilities as a Path of Wrath barbarian, consider multiclassing with:</p>
        <div className="trait">
          <p><strong>Fighter (Champion):</strong> Improved critical hits complement your devastating attacks.</p>
          <p><strong>Rogue (Assassin):</strong> Add stealth and burst damage to your combat repertoire.</p>
          <p><strong>Ranger (Hunter):</strong> Enhance your predatory nature with tracking and combat features.</p>
          <p><strong>Monk (Open Hand):</strong> Add mobility and control to your destructive arsenal.</p>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={pathOfWrathImage} 
                alt="Path of Wrath"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={pathOfWrathImage}
                download="PathOfWrath.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesPathOfWrath; 